import React, { useEffect, useState } from 'react'
import { Box, BoxProps } from '@mui/material'
import { styled } from '@mui/material/styles'

interface IProps {
  acquiredScore: number | null
  beforeFirstQuarter: boolean
  noAssessmentsCompleted: boolean
}

interface ChartBackgroundProps extends BoxProps {
  beforeFirstQuarter: boolean
  noAssessmentsCompleted: boolean
}

const ScoreChartBackground = styled(Box, {
  shouldForwardProp: prop =>
    prop !== 'beforeFirstQuarter' && prop !== 'noAssessmentsCompleted'
})<ChartBackgroundProps>(({ beforeFirstQuarter, noAssessmentsCompleted }) => ({
  backgroundColor:
    beforeFirstQuarter || noAssessmentsCompleted ? '#F7F7F7' : '#F1FAFF',
  borderRadius: '16px 16px 0px 0px',
  display: 'flex',
  alignItems: 'flex-end'
}))

const ScoreChartForeground = styled(Box)<BoxProps>(() => ({
  background: 'linear-gradient(360deg, #9378C6 0%, #0079B6 89.75%)',
  borderRadius: '16px 16px 0px 0px'
}))

const QuarterTotalScoreChart = ({
  acquiredScore,
  beforeFirstQuarter,
  noAssessmentsCompleted
}: IProps) => {
  const [score, setScore] = useState(0)

  useEffect(() => {
    if (acquiredScore !== null && score < acquiredScore) {
      const countUp = setInterval(() => {
        setScore(prevScore => prevScore + 1)
      }, 20)
      return () => clearInterval(countUp)
    }
  }, [acquiredScore, score, setScore])

  return (
    <Box>
      <ScoreChartBackground
        beforeFirstQuarter={beforeFirstQuarter}
        noAssessmentsCompleted={noAssessmentsCompleted}
        sx={{ height: '259px', width: '28px' }}
      >
        <ScoreChartForeground
          sx={{
            height: `${score}%`,
            width: '28px'
          }}
        />
      </ScoreChartBackground>
    </Box>
  )
}

export default QuarterTotalScoreChart
