import React, { useState, useEffect } from 'react'
import {
  Box,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio
} from '@mui/material'
import { DividerLine } from '..'
import { Answer, QuestionState } from 'model/assessment'

interface MultiSelectProps {
  answers: Answer[]
  questionState: QuestionState
  setQuestionState: React.Dispatch<any>
  setFollowupId: React.Dispatch<any>
}

const MultiSelectQuestion = ({
  answers,
  questionState,
  setQuestionState,
  setFollowupId
}: MultiSelectProps) => {
  const [radioSelected, setRadioSelected] = useState(false)
  const [checkSelected, setCheckSelected] = useState(false)
  const [currentSelection, setCurrentSelection] = useState<string[]>(
    questionState.currentQuestionSelectedIds
  )

  useEffect(() => {
    setCurrentSelection(questionState.currentQuestionSelectedIds)
    if (currentSelection.length === 0) {
      setRadioSelected(false)
      setCheckSelected(false)
      return
    }
    const answer: any = answers.find(answer => !answer.hasWeight)
    if (answer && currentSelection.includes(answer.id)) {
      setRadioSelected(true)
      setCheckSelected(false)
    } else {
      setCheckSelected(true)
      setRadioSelected(false)
    }
  }, [answers, currentSelection, questionState])

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value
    let selectedAnswerHasFollowup = answers.filter(
      answer =>
        [...currentSelection, value].includes(answer.id) &&
        answer.hasFollowupQuestion
    )
    if (currentSelection.includes(value)) {
      if (currentSelection.length === 1) {
        setCheckSelected(false)
      } else {
        setCheckSelected(true)
      }
      selectedAnswerHasFollowup = selectedAnswerHasFollowup.filter(
        answer => answer.id !== value
      )
      const newSelected = currentSelection.filter(answer => answer !== value)
      setQuestionState({
        index: 'currentQuestionSelectedIds',
        value: [...newSelected]
      })
    } else {
      setQuestionState({
        index: 'currentQuestionSelectedIds',
        value: [...currentSelection, value]
      })
      setCheckSelected(true)
    }
    setQuestionState({
      index: 'currentQuestionHasFollowup',
      value: selectedAnswerHasFollowup.length > 0
    })
    const followupIds =
      selectedAnswerHasFollowup.length > 0
        ? selectedAnswerHasFollowup[0]?.followupQuestionIds
        : []
    if (questionState.questionsToSubmit.length === 0) {
      setFollowupId(followupIds)
    }
  }

  const handleRadioClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const value = (e.target as HTMLInputElement).value
    if (currentSelection.includes(value)) {
      setQuestionState({
        index: 'currentQuestionSelectedIds',
        value: []
      })
      setRadioSelected(false)
    } else {
      setQuestionState({
        index: 'currentQuestionSelectedIds',
        value: [value]
      })
      setRadioSelected(true)
    }
    setQuestionState({
      index: 'currentQuestionHasFollowup',
      value: false
    })
  }
  return (
    <FormControl
      sx={{ padding: '0 32px', boxSizing: 'border-box', width: '100%' }}
    >
      <FormGroup sx={{ width: '100%' }}>
        {answers
          .filter(answer => answer.hasWeight)
          .map(answer => {
            let answerIsSelected = false
            if (currentSelection.includes(answer.id)) {
              answerIsSelected = true
            }
            return (
              <Box key={answer.id} sx={{ width: '100%' }}>
                <DividerLine item xs={12} />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={answerIsSelected}
                      onChange={e => {
                        handleCheckboxChange(e)
                      }}
                      disabled={radioSelected}
                      value={answer.id}
                      sx={{
                        marginRight: '20px',
                        padding: 0,
                        fontSize: '20px',
                        color: '#050B0E',
                        '& .MuiSvgIcon-root': {
                          height: '20px',
                          width: '20px'
                        }
                      }}
                    />
                  }
                  label={answer.answerText}
                  sx={{
                    fontFamily: 'Roboto',
                    margin: '8px 0',
                    color: radioSelected ? 'rgba(0, 0, 0, 0.26)' : '#050B0E',
                    padding: '8px 0 8px 0',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'flex-start',
                    backgroundColor: answerIsSelected ? '#F7F7F7' : 'none',
                    '& .MuiFormControlLabel-label': {
                      fontSize: '14px',
                      lineHeight: '18px'
                    },
                    '&:hover': {
                      backgroundColor: '#F7F7F7'
                    }
                  }}
                />
              </Box>
            )
          })}
      </FormGroup>
      {answers
        .filter(answer => !answer.hasWeight)
        .map(answer => {
          let answerIsSelected = false
          if (currentSelection.includes(answer.id)) {
            answerIsSelected = true
          }
          return (
            <RadioGroup
              value={answer.id}
              key={answer.id}
              sx={{ width: '100%' }}
            >
              <DividerLine item xs={12} />
              <FormControlLabel
                control={
                  <Radio
                    onClick={e => {
                      handleRadioClick(e)
                    }}
                    value={answer.id}
                    checked={answerIsSelected}
                    disabled={checkSelected}
                    sx={{
                      marginRight: '20px',
                      padding: 0,
                      fontSize: '20px',
                      color: '#050B0E',
                      '& .MuiSvgIcon-root': {
                        height: '20px',
                        width: '20px'
                      }
                    }}
                  />
                }
                label={answer.answerText}
                sx={{
                  fontFamily: 'Roboto',
                  padding: '8px 50px 8px 0',
                  margin: '8px 0 8px 0',
                  width: '100%',
                  color: checkSelected ? 'rgba(0, 0, 0, 0.26)' : '#050B0E',
                  backgroundColor: answerIsSelected ? '#F7F7F7' : 'none',
                  '&:hover': {
                    backgroundColor: '#F7F7F7'
                  },
                  '& .MuiFormControlLabel-label': {
                    fontSize: '14px',
                    lineHeight: '18px'
                  }
                }}
              />
            </RadioGroup>
          )
        })}
    </FormControl>
  )
}

export default MultiSelectQuestion
