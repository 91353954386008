import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'
import { HERO, HERO_W_NO_EI_ACCESS, HERO_W_BASIC_EI_ACCESS } from './constants'
import {
  ImageInfo,
  ImageBlock,
  InfoBlock,
  InfoButton
} from './styledComponents'
import { AccessContext } from 'context/AccessContext'

const ImageInfoBlock = () => {
  const { hasAccessToViewGoalManager } = useContext(AccessContext)
  const { pageType = '' }: any = useLocation().state || {}
  const heroData =
    hasAccessToViewGoalManager && pageType === 'view-goals'
      ? { ...HERO, ...HERO_W_BASIC_EI_ACCESS }
      : { ...HERO, ...HERO_W_NO_EI_ACCESS }

  return (
    <ImageInfo>
      <ImageBlock />
      <InfoBlock>
        <Typography
          variant='formLabel'
          marginBottom={3}
          sx={{
            textTransform: 'uppercase'
          }}
        >
          {heroData.title}
        </Typography>
        <Typography variant='h1' marginBottom={3}>
          {heroData.message}
        </Typography>
        <Typography variant='body16Light' marginBottom={5}>
          {heroData.desc}
        </Typography>
        <Grid container>
          <Grid item xs={7}>
            <InfoButton href={heroData.btnUrl} variant='contained'>
              {heroData.btnTitle}
            </InfoButton>
          </Grid>
        </Grid>
      </InfoBlock>
    </ImageInfo>
  )
}

export default ImageInfoBlock
