import React, { createContext, useContext } from 'react'
import { useQuery } from '@apollo/client'
import { GET_EMPLOYER_ASSESSMENT_HISTORY } from './gql'
import { EmployerAssessmentHistory } from 'model/assessment'
import { MicroServiceContext } from 'context/MicroService'

export const INITIAL_EMPLOYER_ASSESSMENT_HISTORY: EmployerAssessmentHistory = {
  firstQuarter: {
    id: '',
    acquiredScore: 0,
    employerAssessmentCategories: [
      {
        id: '',
        name: 'Defining and Tracking',
        slug: 'defining-tracking',
        acquiredScore: 0
      },
      {
        id: '',
        name: 'Attracting and Sourcing',
        slug: 'attracting-sourcing',
        acquiredScore: 0
      },
      {
        id: '',
        name: 'Interviewing and Engaging',
        slug: 'interviewing-engaging',
        acquiredScore: 0
      },
      {
        id: '',
        name: 'Onboarding and Advancing',
        slug: 'onboarding-advancing',
        acquiredScore: 0
      }
    ]
  },
  secondQuarter: {
    id: '',
    acquiredScore: 0,
    employerAssessmentCategories: [
      {
        id: '',
        name: 'Defining and Tracking',
        slug: 'defining-tracking',
        acquiredScore: 0
      },
      {
        id: '',
        name: 'Attracting and Sourcing',
        slug: 'attracting-sourcing',
        acquiredScore: 0
      },
      {
        id: '',
        name: 'Interviewing and Engaging',
        slug: 'interviewing-engaging',
        acquiredScore: 0
      },
      {
        id: '',
        name: 'Onboarding and Advancing',
        slug: 'onboarding-advancing',
        acquiredScore: 0
      }
    ]
  },
  thirdQuarter: {
    id: '',
    acquiredScore: 0,
    employerAssessmentCategories: [
      {
        id: '',
        name: 'Defining and Tracking',
        slug: 'defining-tracking',
        acquiredScore: 0
      },
      {
        id: '',
        name: 'Attracting and Sourcing',
        slug: 'attracting-sourcing',
        acquiredScore: 0
      },
      {
        id: '',
        name: 'Interviewing and Engaging',
        slug: 'interviewing-engaging',
        acquiredScore: 0
      },
      {
        id: '',
        name: 'Onboarding and Advancing',
        slug: 'onboarding-advancing',
        acquiredScore: 0
      }
    ]
  },
  fourthQuarter: {
    id: '',
    acquiredScore: 0,
    employerAssessmentCategories: [
      {
        id: '',
        name: 'Defining and Tracking',
        slug: 'defining-tracking',
        acquiredScore: 0
      },
      {
        id: '',
        name: 'Attracting and Sourcing',
        slug: 'attracting-sourcing',
        acquiredScore: 0
      },
      {
        id: '',
        name: 'Interviewing and Engaging',
        slug: 'interviewing-engaging',
        acquiredScore: 0
      },
      {
        id: '',
        name: 'Onboarding and Advancing',
        slug: 'onboarding-advancing',
        acquiredScore: 0
      }
    ]
  }
}

type DashboardContextData = {
  employerAssessmentHistory: EmployerAssessmentHistory
}

export const DashboardContext = createContext<DashboardContextData>({
  employerAssessmentHistory: INITIAL_EMPLOYER_ASSESSMENT_HISTORY
})

export const DashboardProvider = ({ children }: any): JSX.Element => {
  const { maestroClient } = useContext(MicroServiceContext)
  const {
    data: employerAssessmentHistory = INITIAL_EMPLOYER_ASSESSMENT_HISTORY,
    loading
  } = useQuery(GET_EMPLOYER_ASSESSMENT_HISTORY, {
    client: maestroClient
  })

  if (loading) return <></>
  return (
    <DashboardContext.Provider
      value={{
        employerAssessmentHistory:
          employerAssessmentHistory?.employerAssessmentHistory
      }}
    >
      {children}
    </DashboardContext.Provider>
  )
}
