import React from 'react'
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'
import { Color } from 'components'

interface Props {
  color?: string
  text?: string
}

const Dot = styled('div')(({ color }: Props) => ({
  width: 12,
  height: 12,
  borderRadius: 6,
  backgroundColor: color ?? Color.grey100,
  display: 'inline-block',
  marginRight: 6
}))

const PlusChip = styled(({ text, ...props }: Props) => (
  <Typography component='div' variant='subtitle2' {...props}>
    {text}
  </Typography>
))(({ color }: Props) => ({
  background: '#F7F7F7',
  borderRadius: '8px',
  display: 'inline-block',
  color: color,
  padding: '4px'
}))

export { Dot, PlusChip }
