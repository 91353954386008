import React, { useState, useContext, useEffect } from 'react'
import { Grid, Typography, Box } from '@mui/material'
import { theme, Color, InfoBanner } from 'components'
import { Category } from 'model/assessment'
import { AssessmentContext } from 'context/Assessment'
import AssessmentProgress from './AssessmentProgress'
import AssessmentSubmitButton from './AssessmentSubmitButton'
import CategoryBox from './CategoryBox'
import { GET_EMPLOYER_ASSESSMENT } from 'context/Assessment/gql'
import { INITIAL_ASSESSMENT } from 'context/Assessment/constants'
import AssessmentStartButton from './AssessmentStartButton'
import { MicroServiceContext } from 'context/MicroService'
import { StyledSnackbar } from 'pages/Dashboard/ScoreDashboard'
import ClearIcon from '@mui/icons-material/Clear'
import { DashboardContext } from 'context/Dashboard'

interface Props {
  answerSubmitted: boolean
  setAnswerSubmitted: React.Dispatch<React.SetStateAction<boolean>>
}

const AssessmentOverview = ({ answerSubmitted, setAnswerSubmitted }: Props) => {
  const { assessmentData, hasActiveAssessment, employerAssessmentData } =
    useContext(AssessmentContext)
  const { employerAssessmentHistory } = useContext(DashboardContext)
  const { maestroClient } = useContext(MicroServiceContext)
  const [showBanner, setShowBanner] = useState(true)
  const [assessmentStarted, setAssessmentStarted] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(answerSubmitted)
  const categories = hasActiveAssessment
    ? employerAssessmentData.employerAssessmentCategories
    : assessmentData.categories

  const hasCompletedAnAssessment =
    employerAssessmentHistory.firstQuarter !== null ||
    employerAssessmentHistory.secondQuarter !== null ||
    employerAssessmentHistory.thirdQuarter !== null ||
    employerAssessmentHistory.fourthQuarter !== null

  const sortCategories = (categories: Category[] | undefined) => {
    const sortedCategories: any = INITIAL_ASSESSMENT.assessment.categories
    if (categories)
      for (let i = 0; i < categories.length; i++) {
        const category = categories[i]
        if (category.name === 'Defining & Tracking') {
          sortedCategories[0] = category
        } else if (category.name === 'Attracting & Sourcing') {
          sortedCategories[1] = category
        } else if (category.name === 'Interviewing & Engaging') {
          sortedCategories[2] = category
        } else {
          sortedCategories[3] = category
        }
      }
    return sortedCategories
  }

  const sortedCategories: Category[] = sortCategories(categories)

  const definingTrackingProgress = sortedCategories[0].progress
  const attractingSourcingProgress = sortedCategories[1].progress
  const interviewingEngagingProgress = sortedCategories[2].progress
  const onboardingAdvancingProgress = sortedCategories[3].progress

  useEffect(() => {
    maestroClient
      .reFetchObservableQueries({
        include: [GET_EMPLOYER_ASSESSMENT]
      })
      .then(() => setAnswerSubmitted(false))
      .then(() => setAssessmentStarted(false))
  }, [
    answerSubmitted,
    setAnswerSubmitted,
    assessmentStarted,
    setAssessmentStarted,
    maestroClient,
    definingTrackingProgress,
    attractingSourcingProgress,
    interviewingEngagingProgress,
    onboardingAdvancingProgress
  ])

  return (
    <Grid
      container
      flexDirection='column'
      flexWrap='nowrap'
      rowSpacing={3}
      marginTop={0}
    >
      <StyledSnackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ padding: '12px 17px', marginTop: '75px' }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
          Progress saved
          <ClearIcon
            onClick={() => setSnackbarOpen(false)}
            sx={{
              marginLeft: '20px',
              fontSize: '16px',
              '&:hover': { cursor: 'pointer' }
            }}
          />
        </Typography>
      </StyledSnackbar>
      <Typography variant='h1' marginBottom={1}>
        Assessment
      </Typography>
      <Grid item container spacing={3}>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            [theme.breakpoints.down('lg')]: {
              marginBottom: 3
            }
          }}
        >
          <Box sx={{ bgcolor: Color.grey50, padding: 3, borderRadius: 1 }}>
            <Typography variant='body1'>
              The Equity Index asks questions in 4 categories, to help figure
              out where you are with DEI. Its goal is to identify bias and
              uncover opportunities to develop a more inclusive workplace. For
              best results, we suggest bringing a few different people and
              perspectives together to provide input. And make sure you answer
              about where you are today, not where you hope to be.{' '}
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={3} xs={6}>
          {hasActiveAssessment ? (
            <AssessmentProgress
              progress={employerAssessmentData?.progress || 0}
            />
          ) : (
            <AssessmentStartButton
              setAssessmentStarted={setAssessmentStarted}
            />
          )}
        </Grid>
        <Grid item lg={3} xs={6}>
          <AssessmentSubmitButton
            employerAssessmentId={
              employerAssessmentData ? employerAssessmentData.id : undefined
            }
          />
        </Grid>
      </Grid>
      {showBanner && !hasActiveAssessment && !hasCompletedAnAssessment && (
        <Grid item>
          {/* {noAssessmentsCompleted ? ( */}
          <InfoBanner
            content={
              'You don’t need to finish it all in one sitting. Feel free to jump into a category and you can save your progress and come back anytime.'
            }
            type='info'
            onClose={() => setShowBanner(false)}
            sx={{
              '& .css-7s9set-MuiGrid-root': {
                '& svg': {
                  backgroundColor: Color.black,
                  borderRadius: '50%',
                  '& path': {
                    fill: '#DCF3FF'
                  },
                  '& path:first-of-type': {
                    fill: Color.black
                  }
                }
              }
            }}
          />
          {/* // ) : (
          //   <InfoBanner
          //     content={'It’s time to retake your assessment!'}
          //     type='info'
          //     onClose={() => setShowBanner(false)}
          //     sx={{
          //       '& .css-7s9set-MuiGrid-root': {
          //         '& svg': {
          //           backgroundColor: Color.black,
          //           borderRadius: '50%',
          //           '& path': {
          //             fill: '#DCF3FF'
          //           },
          //           '& path:first-of-type': {
          //             fill: Color.black
          //           }
          //         }
          //       }
          //     }}
          //   />
          // )} */}
        </Grid>
      )}
      <Grid item>
        {sortedCategories &&
          sortedCategories?.map((category: Category, index: number) => (
            <CategoryBox key={`category-${index}`} category={category} />
          ))}
      </Grid>
    </Grid>
  )
}
export default AssessmentOverview
