import { gql } from '@apollo/client'

export const GET_EMPLOYER_ASSESSMENT_HISTORY = gql`
  query {
    employerAssessmentHistory {
      firstQuarter {
        id
        acquiredScore
        status
      }
      secondQuarter {
        id
        acquiredScore
        status
      }
      thirdQuarter {
        id
        acquiredScore
        status
      }
      fourthQuarter {
        id
        acquiredScore
        status
      }
    }
  }
`
