import React from 'react'
import { Box, Typography, BoxProps } from '@mui/material'
import { styled } from '@mui/material/styles'

const AssessmentProgressContainer = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px',
  height: '100%',
  backgroundColor: '#F1FAFF',
  borderRadius: '8px',
  boxSizing: 'border-box'
}))

const StyledSvg = styled('svg')(() => ({
  height: '100px',
  width: '100px'
}))

const StyledCircle = styled('circle')(() => ({
  fill: 'transparent',
  transformOrigin: '50% 50%',
  transform: 'rotate(270deg)'
}))

interface Props {
  progress: number
}

const AssessmentProgress = ({ progress }: Props) => {
  return (
    <AssessmentProgressContainer>
      <Typography
        variant='subtitle1'
        sx={{
          fontWeight: 700,
          lineHeight: '16px',
          letterSpacing: '0.17em',
          width: '75px',
          color: '#0079B6'
        }}
      >
        {progress}% COMPLETE
      </Typography>
      <Box>
        <StyledSvg>
          <StyledCircle
            cx='50'
            cy='50'
            r='40'
            style={{
              strokeWidth: 5,
              stroke: '#FFFFFF',
              strokeDasharray: '251',
              strokeDashoffset: 0
            }}
          />
          <StyledCircle
            cx='50'
            cy='50'
            r='40'
            style={{
              strokeWidth: 12,
              stroke: '#0079B6',
              strokeDasharray: '251',
              strokeDashoffset: 251 - 2.51 * progress
            }}
          />
        </StyledSvg>
      </Box>
    </AssessmentProgressContainer>
  )
}

export default AssessmentProgress
