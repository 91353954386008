import React, { useState, useEffect } from 'react'
import { Box, BoxProps, Paper, PaperProps, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Color } from 'components'

const TotalScoreContainer = styled(Paper)<PaperProps>(() => ({
  width: '100%',
  height: '460px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '64px 0 48px'
}))

interface BlueCircleProps extends BoxProps {
  noAssessmentsCompleted: boolean
}

const BlueCircleBackground = styled(Box, {
  shouldForwardProp: props => props !== 'noAssessmentsCompleted'
})<BlueCircleProps>(({ noAssessmentsCompleted }) => ({
  backgroundColor: noAssessmentsCompleted ? '#F7F7F7' : '#F1FAFF',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '5px'
}))

interface IProps {
  acquiredScore?: number
  previousAcquiredScore?: number
  previousQuarter?: number
  noAssessmentsCompleted: boolean
}

const TotalAssessmentScore = ({
  acquiredScore,
  previousAcquiredScore,
  previousQuarter,
  noAssessmentsCompleted
}: IProps) => {
  const [score, setScore] = useState(0)
  const [scoreIncrease, setScoreIncrease] = useState(0)

  useEffect(() => {
    if (score < Number(acquiredScore)) {
      const countUp = setInterval(() => {
        setScore(prevScore => prevScore + 1)
      }, 5)
      return () => clearInterval(countUp)
    }
  }, [score, acquiredScore])

  useEffect(() => {
    if (!previousAcquiredScore || !acquiredScore) return
    const difference =
      Number(previousAcquiredScore) > 0
        ? Number(acquiredScore) - Number(previousAcquiredScore)
        : 0
    const roundedDifference = Math.floor(difference)
    if (scoreIncrease === roundedDifference) return

    if (roundedDifference > 0) {
      const countUp = setInterval(() => {
        setScoreIncrease(prevScoreIncrease => prevScoreIncrease + 1)
      }, 50)
      return () => clearInterval(countUp)
    } else {
      const countDown = setInterval(() => {
        setScoreIncrease(prevScoreIncrease => prevScoreIncrease - 1)
      }, 50)
      return () => clearInterval(countDown)
    }
  }, [acquiredScore, previousAcquiredScore, scoreIncrease])

  return (
    <TotalScoreContainer variant='outlined'>
      <Typography
        variant='h6'
        sx={{
          fontSize: '16px',
          lineHeight: '20px',
          letterSpacing: '2px',
          color: noAssessmentsCompleted ? '#ACADAF' : 'black'
        }}
      >
        CURRENT SCORE
      </Typography>
      <BlueCircleBackground
        noAssessmentsCompleted={noAssessmentsCompleted}
        sx={{
          height: '255px',
          width: '255px',
          marginTop: '29px'
        }}
      >
        {!noAssessmentsCompleted && (
          <Typography
            variant='largeTitle'
            sx={{
              fontSize: '120px',
              letterSpacing: '-0.05em',
              fontWeight: 400,
              color: '#0079B6'
            }}
          >
            {score}
          </Typography>
        )}
        {!noAssessmentsCompleted && (
          <Typography
            variant='h1'
            sx={{
              fontSize: '48px',
              letterSpacing: '-0.05em',
              color: '#0079B6',
              fontWeight: 400,
              paddingTop: '60px'
            }}
          >
            /100
          </Typography>
        )}
      </BlueCircleBackground>
      {!noAssessmentsCompleted && (
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '14px' }}>
          <Typography
            variant='subtitle1'
            sx={{
              fontWeight: 700,
              letterSpacing: '0.17em',
              color: '#0079B6',
              padding: '8px 16px',
              backgroundColor: '#F1FAFF',
              borderRadius: '100px',
              marginRight: '8px'
            }}
          >
            {`${scoreIncrease}%`}
          </Typography>
          <Typography
            variant='subtitle1'
            sx={{
              fontWeight: 700,
              letterSpacing: '0.17em',
              color: Color.black
            }}
          >
            {scoreIncrease >= 0
              ? `INCREASE FROM Q${previousQuarter}`
              : `DECREASE FROM Q${previousQuarter}`}
          </Typography>
        </Box>
      )}
    </TotalScoreContainer>
  )
}

export default TotalAssessmentScore
