import React from 'react'
import {
  Paper,
  Button,
  Box,
  Typography,
  PaperProps,
  ButtonProps
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'

const finishAssessmentImageUrl =
  'https://www.datocms-assets.com/54533/1654813837-dashboard_illustration.png'

const FinishAssessmentContainer = styled(Paper)<PaperProps>(() => ({
  padding: '40px 56px 0 56px',
  backgroundColor: '#F0E8FF',
  borderRadius: '8px',
  boxShadow: 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
}))

const StyledButton = styled(Button)<ButtonProps>(() => ({
  padding: '0 16px',
  margin: '17px 0',
  backgroundColor: '#050B0E',
  color: '#FFFFFF',
  borderRadius: '8px',
  whiteSpace: 'nowrap',
  fontFamily: 'Roboto',
  width: '169px',
  height: '48px',
  alignSelf: 'flex-end',
  '&:hover': {
    backgroundColor: '#050B0E',
    color: '#FFFFFF'
  }
}))

const FinishAssessmentBanner = () => {
  const navigate = useNavigate()

  const onClick = () => {
    navigate('/equity-index/assessment')
  }
  return (
    <FinishAssessmentContainer>
      <Typography variant='h6'>
        Looks like you&lsquo;re not done taking the Assessment. Whenever you
        finish, your Dashboard will fill up with data, insights, and other
        helpful info you may want to share with your organization. Ready to
        finish?
      </Typography>
      <StyledButton disableRipple onClick={onClick}>
        Finish Assessment
      </StyledButton>
      <Box
        component='img'
        sx={{
          height: 210,
          width: 397
        }}
        alt='Finish Assessment Cartoon'
        src={finishAssessmentImageUrl}
      />
    </FinishAssessmentContainer>
  )
}

export default FinishAssessmentBanner
