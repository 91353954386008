import React from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Color, TextField } from 'components'
import { CalendarOutlineIcon } from 'assets/icons'
import moment, { Moment } from 'moment'
import { validateMomentDate, toMomentDate } from 'utils/goalManager'

type DatePickerProps = {
  label: string
  value: Moment | null
  defaultValue?: string
  onChange: (val: Moment | null) => void
  onAccept: (val: Moment) => void
  isEndMonth?: boolean
}
const GoalDatePicker = ({
  onChange,
  onAccept,
  defaultValue,
  isEndMonth,
  ...props
}: DatePickerProps) => {
  return (
    <DatePicker
      views={['year', 'month']}
      minDate={moment().startOf('month')}
      onAccept={value => {
        if (value && !value?.isSame(defaultValue, 'month')) {
          onAccept(value)
        }
      }}
      onChange={(value, keyboardValue) => {
        if (keyboardValue) {
          onChange(toMomentDate(keyboardValue, isEndMonth))
        } else if (value) {
          if (isEndMonth) {
            onChange(value?.endOf('month'))
          } else {
            onChange(value)
          }
        } else {
          onChange(value)
        }
      }}
      components={{
        OpenPickerIcon: CalendarOutlineIcon
      }}
      renderInput={({ ...param }) => {
        const isUpdated = !toMomentDate(param.inputProps?.value).isSame(
          defaultValue,
          'month'
        )
        const isValidDate =
          param.inputProps?.value && validateMomentDate(param.inputProps?.value)
        return (
          <TextField
            {...param}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                e.preventDefault()
                if (param.inputProps?.value && isUpdated && isValidDate) {
                  const date = toMomentDate(param.inputProps.value, isEndMonth)
                  onAccept(date)
                }
              }
            }}
            error={isUpdated && !isValidDate && defaultValue ? true : false}
            variant='filled'
          />
        )
      }}
      PopperProps={{ placement: 'bottom-start' }}
      PaperProps={{
        sx: {
          backgroundColor: Color.grey50,
          boxShadow: '3px 3px 12px 2px rgba(155, 157, 159, 0.3)',
          borderRadius: 2,
          '& .PrivatePickersYear-yearButton, .PrivatePickersMonth-root': {
            fontFamily: 'Roboto',
            letterSpacing: 0,
            width: 56,
            height: 32,
            borderRadius: '16px',
            '&.Mui-selected': {
              fontWeight: 600
            }
          },
          '& .PrivatePickersYear-yearButton': {
            backgroundColor: Color.grey200
          },
          '& .MuiMonthPicker-root': {
            paddingRight: '60px',
            '.PrivatePickersMonth-root': {
              marginRight: '8px',
              marginLeft: '16px',
              flex: '1 0 20%'
            }
          }
        }
      }}
      {...props}
    />
  )
}
export default GoalDatePicker
