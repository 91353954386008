import React, { useContext } from 'react'
import RouteProvider from './routes'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { MicroServiceContext } from 'context/MicroService'
import createApolloClient from 'utils/createApolloClient'
import { AccessProvider } from 'context/AccessContext'

const App = () => {
  const { maestroClient } = useContext(MicroServiceContext)

  return (
    <ApolloProvider
      client={
        (window as any).__POWERED_BY_QIANKUN__
          ? maestroClient
          : createApolloClient()
      }
    >
      <AccessProvider>
        <Router>
          <RouteProvider />
        </Router>
      </AccessProvider>
    </ApolloProvider>
  )
}

export default App
