import { CarouselSlide } from 'model/assessment'

export const PageSlides: CarouselSlide[] = [
  {
    title: 'Set goals and build your DEI roadmap',
    content:
      'The DEI journey spans across 4 major sections/categories: Defining and Tracking, Attracting and Sourcing, Interviewing and Engaging, and Onboarding and Advancing.',
    image: 'https://www.datocms-assets.com/62332/1643494661-slide_1.svg'
  },
  {
    title: 'Our metrics let you track, measure, and share your progress',
    content:
      'Take advantage of our Equity Index to communicate your wins to employees, senior leaders and, if you want, externally too.',
    image: 'https://www.datocms-assets.com/62332/1643494669-slide_3.svg'
  },
  {
    title: 'Transform data-driven insights into strategy and action',
    content:
      "In response to your answers, we'll recommend specific tailored goals. And to help you reach them, we'll equip you with best practices, checklists, case studies, and encouragement.",
    image: 'https://www.datocms-assets.com/62332/1643494664-slide_2.svg'
  },
  {
    title: "When you're ready, let's get started",
    content:
      "Our assessment is engineered to give you the big picture, showing you where you are on your DEI journey and how you can take next steps — even if it's the first step.",
    image: 'https://www.datocms-assets.com/62332/1643494671-slide_4.svg'
  }
]
