import React from 'react'
import { MenuItem, Typography } from '@mui/material'

const MenuItemNoMembers = () => (
  <MenuItem
    disabled
    sx={{
      padding: '0 4px',
      height: '4.5rem',
      '&.Mui-disabled': { opacity: 1, whiteSpace: 'normal' },
      width: '22.5rem'
    }}
  >
    <Typography variant='body16'>
      No Equity Index users found. Ask your Admin to invite team members to
      Mathison or grant more users Equity Index access.
    </Typography>
  </MenuItem>
)

export default MenuItemNoMembers
