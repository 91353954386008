import React from 'react'
import QuarterCategoryProgressChart from './QuarterCategoryProgressChart'
import { Grid, Typography, GridProps, Paper, PaperProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { theme, Color } from 'components'
import { EmployerAssessmentHistory } from 'model/assessment'
import { groupScoresByCategory } from 'utils/dashboardUtils'

const QuarterCategoryProgressContainer = styled(Paper)<PaperProps>(() => ({
  width: '100%',
  padding: '40px 0 50px 40px',
  marginLeft: 0,
  display: 'flex',
  flexWrap: 'wrap'
}))

const SpaceBetweenGrid = styled(Grid)<GridProps>(() => ({
  display: 'flex',
  justifyContent: 'space-between'
}))

const BorderTopGrid = styled(Grid)<GridProps>(() => ({
  borderTop: '2px solid #E5E5E5',
  padding: '9px 0 0 0'
}))
interface IProps {
  employerAssessmentHistory: EmployerAssessmentHistory
  isVisible: boolean
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
  downloadClicked: boolean
  setGraphComplete: React.Dispatch<React.SetStateAction<boolean>>
  myFirstQuarter: number
  noAssessmentsCompleted: boolean
}

const QuarterCategoryProgress = ({
  employerAssessmentHistory,
  isVisible,
  setIsVisible,
  downloadClicked,
  setGraphComplete,
  myFirstQuarter,
  noAssessmentsCompleted
}: IProps) => {
  const stages = ['Leading', 'Engaged', 'Aware', 'Discovering']
  const categoryNames = [
    'Defining and Tracking',
    'Attracting and Sourcing',
    'Interviewing and Engaging',
    'Onboarding and Advancing'
  ]

  const groupedScores = groupScoresByCategory(employerAssessmentHistory)

  return (
    <QuarterCategoryProgressContainer variant='outlined'>
      {groupedScores.map((quarterScores, idx) => (
        <Grid
          item
          key={idx}
          sx={{
            width: '25%',
            paddingRight: '50px',
            [theme.breakpoints.down(1400)]: {
              width: '50%',
              marginBottom: idx < 2 ? '40px' : 0
            },
            [theme.breakpoints.down(950)]: {
              width: '100%',
              marginBottom: idx < 3 ? '40px' : 0
            }
          }}
        >
          <Typography
            variant='body1'
            sx={{
              fontWeight: 700,
              color: Color.black,
              marginBottom: '36px'
            }}
          >
            {categoryNames[idx]}
          </Typography>
          <Grid container columnSpacing={1} sx={{ width: '100%' }}>
            <Grid item xs={6}>
              {stages.map((stage, index) => (
                <Typography
                  key={index}
                  variant='body2'
                  sx={{
                    borderTop: '2px solid #E5E5E5',
                    paddingTop: '6px',
                    marginBottom: '34px'
                  }}
                >
                  {stage}
                </Typography>
              ))}
            </Grid>
            <SpaceBetweenGrid item xs={6} sx={{ height: '248px' }}>
              {quarterScores.map((score, index) => (
                <QuarterCategoryProgressChart
                  key={index}
                  idx={idx}
                  acquiredScore={score ? score.acquiredScore : 0}
                  isVisible={isVisible}
                  setIsVisible={setIsVisible}
                  downloadClicked={downloadClicked}
                  setGraphComplete={setGraphComplete}
                  beforeFirstQuarter={index < myFirstQuarter}
                  noAssessmentsCompleted={noAssessmentsCompleted}
                />
              ))}
            </SpaceBetweenGrid>
            <BorderTopGrid
              container
              columnSpacing={1}
              sx={{ marginLeft: '8px' }}
            >
              <Grid item xs={5.8} />
              <SpaceBetweenGrid item xs={6.2}>
                <Typography
                  variant='body2'
                  sx={{
                    color:
                      myFirstQuarter >= 1
                        ? '#747779'
                        : noAssessmentsCompleted
                        ? '#ACADAF'
                        : '#050B0E'
                  }}
                >
                  Q1
                </Typography>
                <Typography
                  variant='body2'
                  sx={{
                    color:
                      myFirstQuarter >= 2
                        ? '#747779'
                        : noAssessmentsCompleted
                        ? '#ACADAF'
                        : '#050B0E'
                  }}
                >
                  Q2
                </Typography>
                <Typography
                  variant='body2'
                  sx={{
                    color:
                      myFirstQuarter >= 3
                        ? '#747779'
                        : noAssessmentsCompleted
                        ? '#ACADAF'
                        : '#050B0E'
                  }}
                >
                  Q3
                </Typography>
                <Typography
                  variant='body2'
                  sx={{ color: noAssessmentsCompleted ? '#ACADAF' : '#050B0E' }}
                >
                  Q4
                </Typography>
              </SpaceBetweenGrid>
            </BorderTopGrid>
          </Grid>
        </Grid>
      ))}
    </QuarterCategoryProgressContainer>
  )
}

export default QuarterCategoryProgress
