import React, { useState, useEffect } from 'react'
import { Box, Typography, Grid, GridProps, BoxProps } from '@mui/material'
import { theme } from 'components'
import { styled } from '@mui/material/styles'

interface IProps {
  acquiredScore?: number
  noAssessmentsCompleted: boolean
}

interface ChartContainerProps extends BoxProps {
  noAssessmentsCompleted: boolean
}

const ChartContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'noAssessmentsCompleted'
})<ChartContainerProps>(({ noAssessmentsCompleted }) => ({
  height: 'inherit',
  width: '100%',
  margin: noAssessmentsCompleted ? '40px 0 0px' : '75px 0 0',
  [theme.breakpoints.up('sm')]: {
    minHeight: '235px'
  },
  [theme.breakpoints.up('md')]: {
    minHeight: '205px'
  },
  [theme.breakpoints.up('lg')]: {
    minHeight: '175px'
  },
  [theme.breakpoints.up('xl')]: {
    minHeight: '159px'
  }
}))

const PhaseContainer = styled(Grid)<GridProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  borderRight: '2px solid #E5E5E5',
  minHeight: '159px',
  zIndex: 1,
  padding: '63px 0 0 15px',
  overflow: 'hidden'
}))

interface ScoreBarContainerProps extends GridProps {
  noAssessmentsCompleted: boolean
}

const ScoreBarContainer = styled(Grid, {
  shouldForwardProp: prop => prop !== 'noAssessmentsCompleted'
})<ScoreBarContainerProps>(({ noAssessmentsCompleted }) => ({
  width: '100%',
  height: '53px',
  backgroundColor: noAssessmentsCompleted ? '#F7F7F7' : '#F1FAFF',
  position: 'relative'
}))

const ScoreBar = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  height: '53px',
  position: 'absolute',
  background:
    'linear-gradient(90deg, #9378C6 3.01%, #5AB2DE 46.56%, #0079B6 93.89%)'
}))

const ScoreIndicatorBar = styled(Box)<BoxProps>(() => ({
  height: '60px',
  width: '6px',
  backgroundColor: '#050B0E',
  borderRadius: '12px'
}))

const ScoreCircleBackground = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '58px',
  width: '58px',
  backgroundColor: '#050B0E',
  borderRadius: '50%',
  position: 'relative',
  top: '-51px',
  left: '-26px'
}))

const DevelopmentStageChart = ({
  acquiredScore,
  noAssessmentsCompleted
}: IProps) => {
  const [score, setScore] = useState(0)

  useEffect(() => {
    if (score < Number(acquiredScore)) {
      const countUp = setInterval(() => {
        setScore(prevScore => prevScore + 1)
      }, 5)
      return () => clearInterval(countUp)
    }
  }, [score, acquiredScore])

  return (
    <ChartContainer noAssessmentsCompleted={noAssessmentsCompleted}>
      <ScoreBarContainer
        container
        noAssessmentsCompleted={noAssessmentsCompleted}
      >
        {!noAssessmentsCompleted && (
          <ScoreBar sx={{ width: `${score}%` }}>
            <ScoreIndicatorBar>
              <ScoreCircleBackground>
                <Typography variant='h5' sx={{ color: '#FFFFFF' }}>
                  {score}
                </Typography>
              </ScoreCircleBackground>
            </ScoreIndicatorBar>
          </ScoreBar>
        )}
        <PhaseContainer item xs={3} sx={{ borderLeft: '2px solid #E5E5E5' }}>
          <Typography
            variant='body2'
            sx={{
              marginBottom: '13px',
              color: noAssessmentsCompleted ? '#ACADAF' : 'black'
            }}
          >
            Stage 1
          </Typography>
          <Typography
            variant='h6'
            sx={{
              fontSize: '16px',
              color: noAssessmentsCompleted ? '#ACADAF' : 'black'
            }}
          >
            Discovering
          </Typography>
          <Typography
            variant='body2'
            sx={{
              width: '85%',
              color: noAssessmentsCompleted ? '#ACADAF' : 'black'
            }}
          >
            Just beginning your DEI journey and discovering best practices
          </Typography>
        </PhaseContainer>
        <PhaseContainer item xs={3}>
          <Typography
            variant='body2'
            sx={{
              marginBottom: '13px',
              color: noAssessmentsCompleted ? '#ACADAF' : 'black'
            }}
          >
            Stage 2
          </Typography>
          <Typography
            variant='h6'
            sx={{
              fontSize: '16px',
              color: noAssessmentsCompleted ? '#ACADAF' : 'black'
            }}
          >
            Aware
          </Typography>
          <Typography
            variant='body2'
            sx={{
              width: '85%',
              color: noAssessmentsCompleted ? '#ACADAF' : 'black'
            }}
          >
            Widespread awareness of needs and goals to achieve DEI success
          </Typography>
        </PhaseContainer>
        <PhaseContainer item xs={3}>
          <Typography
            variant='body2'
            sx={{
              marginBottom: '13px',
              color: noAssessmentsCompleted ? '#ACADAF' : 'black'
            }}
          >
            Stage 3
          </Typography>
          <Typography
            variant='h6'
            sx={{
              fontSize: '16px',
              color: noAssessmentsCompleted ? '#ACADAF' : 'black'
            }}
          >
            Engaged
          </Typography>
          <Typography
            variant='body2'
            sx={{
              width: '85%',
              color: noAssessmentsCompleted ? '#ACADAF' : 'black'
            }}
          >
            Best practices are being activated but not all are fully scaled
          </Typography>
        </PhaseContainer>
        <PhaseContainer item xs={3}>
          <Typography
            variant='body2'
            sx={{
              marginBottom: '13px',
              color: noAssessmentsCompleted ? '#ACADAF' : 'black'
            }}
          >
            Stage 4
          </Typography>
          <Typography
            variant='h6'
            sx={{
              fontSize: '16px',
              color: noAssessmentsCompleted ? '#ACADAF' : 'black'
            }}
          >
            Leading
          </Typography>
          <Typography
            variant='body2'
            sx={{
              width: '85%',
              color: noAssessmentsCompleted ? '#ACADAF' : 'black'
            }}
          >
            Scaled and widespread impact across all four categories
          </Typography>
        </PhaseContainer>
      </ScoreBarContainer>
    </ChartContainer>
  )
}

export default DevelopmentStageChart
