import React, { useContext, useReducer } from 'react'
import { Grid, Button } from '@mui/material'
import { GoalManagerContext } from 'context/GoalManager'
import { SwitchButton, Dialog } from 'components'
import { ACTIVE_STATUS } from 'model/goalManager'
import { DialogState } from 'model'
const DIALOG_INITIAL_VALUES: DialogState = {
  open: false,
  isSubmitting: false
}

const HeaderActions = () => {
  const { selectedGoal, submitEmployerGoal } = useContext(GoalManagerContext)
  const [dialogState, setDialogState] = useReducer(
    (state: DialogState, action: any) => {
      switch (action.type) {
        case 'close':
          return {
            ...state,
            isSubmitting: false,
            open: false
          }
        case 'loading':
          return {
            ...state,
            isSubmitting: true
          }
        default:
          return { ...state, [action.index]: action.value }
      }
    },
    DIALOG_INITIAL_VALUES
  )

  const { id, activeStatus } = selectedGoal!
  return (
    <>
      <Dialog
        open={dialogState.open}
        onClose={() => setDialogState({ type: 'close' })}
        title='Do you want to mark this goal as complete?'
        content='Ensure every role has a diverse and inclusive interview panel to ensure positive candidate experience'
        banner={{
          type: 'warning',
          content: 'Goals that are marked complete can’t be reopened.'
        }}
        cancelProps={{
          disabled: dialogState.isSubmitting,
          onClick: () => {
            setDialogState({ type: 'close' })
          }
        }}
        confirmProps={{
          loading: dialogState.isSubmitting,
          onClick: () => {
            setDialogState({ type: 'loading' })
            submitEmployerGoal(id, { isCompleted: true }, 'Goal')
            setDialogState({ type: 'close' })
          }
        }}
      ></Dialog>
      <Grid container marginBottom='48px' alignItems='center'>
        <Grid item xs={6}>
          <SwitchButton
            isChecked={activeStatus === ACTIVE_STATUS.ACTIVE || false}
            setIsChecked={bool => {
              submitEmployerGoal(
                id,
                {
                  activeStatus: bool
                    ? ACTIVE_STATUS.ACTIVE
                    : ACTIVE_STATUS.INACTIVE
                },
                'Active status'
              )
            }}
          >
            GOAL IS {activeStatus === ACTIVE_STATUS.ACTIVE || 'IN'}
            ACTIVE
          </SwitchButton>
        </Grid>
        <Button
          variant='contained'
          color='primary'
          sx={{ marginLeft: 'auto', padding: '10px 24px' }}
          onClick={() => {
            setDialogState({ index: 'open', value: true })
          }}
          disabled={activeStatus === ACTIVE_STATUS.INACTIVE}
        >
          Complete goal
        </Button>
      </Grid>
    </>
  )
}

export default HeaderActions
