import React, { useContext, useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { Grid, Typography, GridProps } from '@mui/material'
import { PageSlides } from './PageCarousel/constants'
import PageCarousel from './PageCarousel'
import ScoreDashboard from './ScoreDashboard'
import { DashboardContext } from 'context/Dashboard'
import DownloadDashboardButton from './DownloadDashboardButton'
import { useQuery } from '@apollo/client'
import {
  CHECK_EMPLOYER_ASSESSMENT_STATUS,
  GET_EMPLOYER_ASSESSMENT_HISTORY
} from 'context/Dashboard/gql'
import { MicroServiceContext } from 'context/MicroService'
import { AccessContext } from 'context/AccessContext'

const SpaceBetweenGrid = styled(Grid)<GridProps>(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between'
}))
const Dashboard = () => {
  const { employerAssessmentHistory } = useContext(DashboardContext)
  const { maestroClient } = useContext(MicroServiceContext)
  const { hasAccessToViewDashboard } = useContext(AccessContext)
  const noAssessmentsCompleted =
    employerAssessmentHistory &&
    employerAssessmentHistory?.firstQuarter === null &&
    employerAssessmentHistory?.secondQuarter === null &&
    employerAssessmentHistory?.thirdQuarter === null &&
    employerAssessmentHistory?.fourthQuarter === null

  const { data, loading } = useQuery(CHECK_EMPLOYER_ASSESSMENT_STATUS, {
    client: maestroClient
  })

  useEffect(() => {
    maestroClient.reFetchObservableQueries({
      include: [GET_EMPLOYER_ASSESSMENT_HISTORY]
    })
  }, [maestroClient])

  const assessmentStarted = data?.employerAssessment !== null

  const [isVisible, setIsVisible] = useState(false)
  const [downloadClicked, setDownloadClicked] = useState(false)
  const [graphComplete, setGraphComplete] = useState(false)

  if (loading) return <></>

  return (
    <Grid sx={{ width: '100%' }}>
      <SpaceBetweenGrid>
        <Typography variant='h1' marginBottom={1}>
          Dashboard
        </Typography>
        {!noAssessmentsCompleted && hasAccessToViewDashboard && (
          <DownloadDashboardButton
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            downloadClicked={downloadClicked}
            setDownloadClicked={setDownloadClicked}
            graphComplete={graphComplete}
          />
        )}
      </SpaceBetweenGrid>
      {assessmentStarted || !noAssessmentsCompleted ? (
        <ScoreDashboard
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          downloadClicked={downloadClicked}
          setGraphComplete={setGraphComplete}
          noAssessmentsCompleted={noAssessmentsCompleted}
        />
      ) : (
        <PageCarousel slides={PageSlides} />
      )}
    </Grid>
  )
}
export default Dashboard
