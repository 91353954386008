import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef
} from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { GoalCountInit } from 'constants/GoalManager'
import {
  filterGoalsByCategory,
  sortGoalsByPhaseState,
  getGoalCount
} from 'utils/goalManager'
import {
  USER_GOAL_PREFERENCE,
  EMPLOYER_GOALS,
  UPDATE_EMPLOYER_GOAL,
  UPDATE_EMPLOYER_PREFERENCE,
  GET_EMPLOYER_STAFF_ACCOUNTS
} from './gql'
import {
  PHASE_STATE,
  GOAL_CATEGORY,
  VIEW_STATE,
  Goal,
  GoalNode,
  UpdatingEmployerGoal,
  MemberEdge,
  GoalCounter
} from 'model/goalManager'
import { MicroServiceContext } from 'context/MicroService'
import { useSearchParams } from 'react-router-dom'
export type GoalManager = {
  selectedGoal?: Goal
  goals: GoalNode[]
  goalCount: GoalCounter
  setSelectedGoalId: (str: string) => void
  phase: PHASE_STATE
  category: string
  setCategory: (cate: GOAL_CATEGORY) => void
  setPhase: (phase: PHASE_STATE) => void
  viewState: VIEW_STATE
  setViewState: (view: VIEW_STATE) => void
  isLoading: boolean
  submitEmployerGoal: (
    id: string,
    input: UpdatingEmployerGoal,
    fieldName?: string
  ) => void
  isBannerOpen: boolean
  setIsBannerOpen: (bool: boolean) => void
  membersWithAccess: MemberEdge[]
  hasAccessToSearchParamGoal: boolean
}

export const GoalManagerContext = createContext<GoalManager>({
  selectedGoal: undefined,
  goals: [],
  goalCount: GoalCountInit,
  setSelectedGoalId: () => {},
  setCategory: () => {},
  setPhase: () => {},
  phase: PHASE_STATE.all,
  category: 'attracting_sourcing',
  viewState: VIEW_STATE.card,
  setViewState: () => {},
  isLoading: true,
  submitEmployerGoal: () => {},
  isBannerOpen: true,
  setIsBannerOpen: () => {},
  membersWithAccess: [],
  hasAccessToSearchParamGoal: false
})

export const GoalManagerProvider = ({ children }: any): JSX.Element => {
  const [selectedGoalId, setSelectedGoalId] = useState('')
  const [isBannerOpen, setIsBannerOpen] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [viewState, setViewState] = useState(VIEW_STATE.card)
  const [phase, setPhase] = useState<PHASE_STATE>(PHASE_STATE.all)
  const [category, setCategory] = useState<string>('all')
  const { displayNotification, ketchupClient, currentAccount } =
    useContext(MicroServiceContext)
  const { data: rawEmployerGoals } = useQuery(EMPLOYER_GOALS, {
    variables: { employerId: currentAccount.employer.id }
  })
  const { data: rawUserGoalPreference, previousData } = useQuery(
    USER_GOAL_PREFERENCE,
    { variables: { userId: currentAccount.id } }
  )
  const { data: employerStaffWithAccess } = useQuery(
    GET_EMPLOYER_STAFF_ACCOUNTS,
    {
      client: ketchupClient,
      variables: {
        first: 1000,
        ehiAccessLevels: ['FULL_EHI_ACCESS', 'BASIC_EHI_ACCESS']
      }
    }
  )
  const [updateEmployerGoal, { error: updateGoalError }] =
    useMutation(UPDATE_EMPLOYER_GOAL)
  const [updateUserGoalPreference] = useMutation(UPDATE_EMPLOYER_PREFERENCE)
  const { employerGoals } = rawEmployerGoals || {}
  const [searchParams, setSearchParams] = useSearchParams()
  const [hasAccessToSearchParamGoal, setHasAccessToSearchParamGoal] =
    useState(true)

  const firstRender = useRef(true)

  useEffect(() => {
    if (rawUserGoalPreference && !previousData) {
      const { userGoalPreference } = rawUserGoalPreference
      if (userGoalPreference) {
        const searchParamId = searchParams.get('id') || ''
        const { selectedCategory, selectedPhase } = userGoalPreference
        let isUserGoalPrefBehavior = false
        if (employerGoals && searchParamId.length > 0) {
          const edges = [...employerGoals.edges]
          const searchParamGoal: GoalNode = edges.find(
            ({ node }) => node.id === searchParamId
          )
          if (searchParamGoal) {
            const cateOverride =
              searchParamGoal.node.category === selectedCategory
                ? selectedCategory
                : 'all'
            const phaseOverride =
              searchParamGoal.node.phase === selectedPhase
                ? selectedPhase
                : PHASE_STATE.all
            setCategory(cateOverride)
            setPhase(phaseOverride)
            setSelectedGoalId(searchParamGoal.node.id)
          } else {
            isUserGoalPrefBehavior = true
            setHasAccessToSearchParamGoal(false)
          }
        } else {
          isUserGoalPrefBehavior = true
        }
        if (isUserGoalPrefBehavior) {
          selectedCategory && setCategory(selectedCategory)
          selectedPhase && setPhase(selectedPhase)
        }
      }
      // setViewState(selectedLayoutView)  Archived
    }
    if (rawEmployerGoals) {
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [rawUserGoalPreference, rawEmployerGoals])

  useEffect(() => {
    if (employerGoals) {
      const edges = [...employerGoals.edges]
      let goals = filterGoalsByCategory(edges, category)
      goals = sortGoalsByPhaseState(goals, phase)
      const persistedGoal: GoalNode | undefined = goals.find(
        ({ node }) => node.id === selectedGoalId
      )
      if (persistedGoal) {
        searchParams.set('id', selectedGoalId)
      } else {
        if (goals.length > 0) {
          const goalId = goals[0].node.id
          setSelectedGoalId(goalId)
          searchParams.set('id', goalId)
        } else {
          setSelectedGoalId('')
          searchParams.delete('id')
        }
      }
      setSearchParams(searchParams, { replace: true })
    }
    if (firstRender.current === true) {
      firstRender.current = false
      return
    }
    submitUserGoalPreference(currentAccount.id, category, phase)
    // eslint-disable-next-line
  }, [phase, category, selectedGoalId])

  const submitEmployerGoal = async (
    id: string,
    input: UpdatingEmployerGoal,
    fieldName?: string
  ) => {
    await updateEmployerGoal({
      variables: { input: { id: id, attributes: input } }
    })
    if (fieldName === 'Goal Owner Removal') {
      !updateGoalError && displayNotification(`Goal Owner removed`)
    } else {
      !updateGoalError && displayNotification(`${fieldName} updated`)
    }
  }
  const submitUserGoalPreference = (
    id: string,
    selectedCategory: string,
    selectedPhase?: string,
    selectedLayoutView?: string
  ) => {
    updateUserGoalPreference({
      variables: {
        input: {
          userId: id,
          preferences: {
            selectedCategory: selectedCategory,
            selectedPhase: selectedPhase || 'all',
            selectedLayoutView: selectedLayoutView || 'card'
          }
        }
      }
    })
  }

  let goals: GoalNode[] = []
  let goalCount: GoalCounter = GoalCountInit
  let selectedGoal: Goal | undefined = undefined
  if (employerGoals) {
    const edges = [...employerGoals.edges]
    goalCount = getGoalCount(edges)
    goals = filterGoalsByCategory(edges, category)
    goals = sortGoalsByPhaseState(goals, phase)
    selectedGoal = (
      goals.find((goal: any) => goal.node.id === selectedGoalId) || goals[0]
    )?.node
  }
  const membersWithAccess = employerStaffWithAccess?.employerStaffAccounts.edges

  return (
    <GoalManagerContext.Provider
      value={{
        selectedGoal,
        goals,
        goalCount,
        setSelectedGoalId,
        phase,
        category,
        setPhase,
        setCategory,
        viewState,
        setViewState,
        isLoading,
        submitEmployerGoal,
        isBannerOpen,
        setIsBannerOpen,
        membersWithAccess,
        hasAccessToSearchParamGoal
      }}
    >
      {children}
    </GoalManagerContext.Provider>
  )
}
