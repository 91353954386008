import React, { ReactElement, useState, useContext } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import AssessmentOverview from './AssessmentOverview'
import AssessmentQuestion from './AssessmentQuestion'
import { AssessmentProvider } from 'context/Assessment'
import { AccessContext } from 'context/AccessContext'

interface Props {
  setHideSidebar: (hideSidebar: boolean) => void
}

const Assessment = ({ setHideSidebar }: Props): ReactElement => {
  const navigate = useNavigate()
  const [answerSubmitted, setAnswerSubmitted] = useState(false)
  const { hasAccessToViewAssessment } = useContext(AccessContext)

  if (!hasAccessToViewAssessment) {
    navigate('/')
  }

  return (
    <AssessmentProvider>
      <Routes>
        <Route
          path=''
          element={
            <AssessmentOverview
              answerSubmitted={answerSubmitted}
              setAnswerSubmitted={setAnswerSubmitted}
            />
          }
        />
        <Route
          path='/:slug/:questionOrder'
          element={
            <AssessmentQuestion
              setHideSidebar={setHideSidebar}
              setAnswerSubmitted={setAnswerSubmitted}
              answerSubmitted={answerSubmitted}
            />
          }
        />
      </Routes>
    </AssessmentProvider>
  )
}

export default Assessment
