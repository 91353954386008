import React, { useContext } from 'react'
import { GoalManagerContext } from 'context/GoalManager'
import { GoalCounter, GOAL_CATEGORY } from 'model/goalManager'
import { Grid, Typography, Paper } from '@mui/material'
import { Color } from 'components'
import { Dot, PlusChip } from './styledComponents'
import { CATEGORY_TEXT_COLOR } from 'constants/'

interface Props {
  goalCategory: string
}

const MiniCard = ({ goalCategory }: Props) => {
  const { goalCount } = useContext(GoalManagerContext)
  const { active, total } = goalCount[goalCategory as keyof GoalCounter]
  const title = GOAL_CATEGORY[goalCategory as keyof typeof GOAL_CATEGORY]
  const titleParts = title.split(' ')
  const color = CATEGORY_TEXT_COLOR[title];

  return (
    <Grid item xs={2.4}>
      <Paper variant='outlined' sx={{ padding: '16px', paddingBottom: '8px' }}>
        <Typography variant='body16' component='div'>
          {titleParts.shift()}
        </Typography>
        <Typography variant='body16'>{titleParts.join(' ')}</Typography>
        <Typography
          variant='h2'
          marginTop='8px'
          marginBottom='8px'
          color={color}
        >
          {active}
        </Typography>

        {[0, 1, 2].map(index => (
          <Dot key={`dot-${index+1}`} color={index < active ? color : Color.grey100} />
        ))}
        {total > 3 ? (
          <PlusChip color={color} text={`+${total - 3}`} />
        ) : null}
      </Paper>
    </Grid>
  )
}
export default MiniCard
