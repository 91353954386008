import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { Grid, Tabs, Tab } from '@mui/material'
import { TabsUnstyled } from '@mui/base'
import { Color, PhaseTab, PhaseTabList, CircularProgress } from 'components'
import { PHASE_STATE, VIEW_STATE, CATEGORIES } from 'model/goalManager'
import CardView from './CardView'
import { GoalManagerContext } from 'context/GoalManager'
import GoalTable from './ListView'
import ElevationScroll from './ElevationScroll'
import { errorPathname } from 'routes/constants'

const CategoryPanel = () => {
  const {
    category,
    phase,
    setCategory,
    setPhase,
    goals,
    goalCount,
    viewState,
    isLoading,
    hasAccessToSearchParamGoal
  } = useContext(GoalManagerContext)

  const LoadingCircular = () => (
    <Grid
      item
      flexGrow={1}
      container
      alignItems='center'
      justifyContent='center'
    >
      <CircularProgress size={40} thickness={4} />
    </Grid>
  )

  if (isLoading) return <LoadingCircular />

  if (goalCount.all.total > 0) {
    if (hasAccessToSearchParamGoal) {
      const categoryPosition = (window as any).__POWERED_BY_QIANKUN__ ? 56 : 0
      return (
        <Grid item container marginTop='16px' flexGrow={1} direction='column'>
          <Grid
            item
            sx={{
              position: 'sticky',
              top: `${categoryPosition}px`,
              zIndex: 1200,
              borderBottom: `1px solid ${Color.grey300}`
            }}
          >
            <Tabs
              value={category}
              onChange={(_, value) => {
                setCategory(value)
              }}
              variant='fullWidth'
            >
              {CATEGORIES.map((category: any, index: number) => (
                <Tab
                  label={category.label}
                  id={`category_${category}`}
                  key={index}
                  value={category.value}
                />
              ))}
            </Tabs>
          </Grid>
          <ElevationScroll>
            <Grid
              item
              container
              padding={4}
              alignItems='center'
              borderLeft={`1px solid ${Color.grey300}`}
              borderRight={`1px solid ${Color.grey300}`}
              sx={{
                bgcolor: '#fff',
                position: 'sticky',
                top: `${categoryPosition + 49}px`,
                zIndex: 1200,
                transition: 'box-shadow 0.3s'
              }}
            >
              <Grid item xs={4.5}>
                <TabsUnstyled
                  value={phase}
                  onChange={(_, value) => {
                    setPhase(value as PHASE_STATE)
                  }}
                >
                  <PhaseTabList>
                    <PhaseTab value={PHASE_STATE.all}>All</PhaseTab>
                    <PhaseTab value={PHASE_STATE.current}>Current</PhaseTab>
                    <PhaseTab value={PHASE_STATE.later}>Later</PhaseTab>
                    <PhaseTab value={PHASE_STATE.inactive}>Inactive</PhaseTab>
                    <PhaseTab value={PHASE_STATE.completed}>Completed</PhaseTab>
                  </PhaseTabList>
                </TabsUnstyled>
              </Grid>
              <Grid item xs={7.5} container justifyContent='flex-end'>
                {/* {viewState === VIEW_STATE.card && (                            //Archived 
              <ViewButton
                onClick={() => setViewState(VIEW_STATE.list)}
                endIcon={<ListIcon />}
              >
                <Typography variant='subtitle2' lineHeight='normal'>
                  LIST VIEW
                </Typography>
              </ViewButton>
            )}
            {viewState === VIEW_STATE.list && (
              <ViewButton
                onClick={() => setViewState(VIEW_STATE.card)}
                endIcon={<CardIcon />}
              >
                <Typography variant='subtitle2' lineHeight='normal'>
                  CARD VIEW
                </Typography>
              </ViewButton>
            )} */}
              </Grid>
            </Grid>
          </ElevationScroll>
          {viewState === VIEW_STATE.card && <CardView goals={goals}></CardView>}
          {viewState === VIEW_STATE.list && <GoalTable />}
        </Grid>
      )
    }
    return <Navigate to={errorPathname} state={{ pageType: 'view-goals' }} />
  }
  return <Navigate to={errorPathname} />
}

export default CategoryPanel
