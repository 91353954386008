import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Grid, Paper, PaperProps, Typography, Box } from '@mui/material'
import {
  ArrowDropDownRounded as ArrowDropDownIcon,
  ArrowDropUpRounded as ArrowDropUpIcon
} from '@mui/icons-material'
import { Color, theme, ArrowCircleRightIcon } from 'components'
import CategoryQuestionList from './CategoryQuestionList'
import { CategoryStatus, Category, Question } from 'model/assessment'
import { CATEGORY_TEXT_COLOR } from 'constants/'
import { styled } from '@mui/material/styles'
import { useQuery } from '@apollo/client'
import { GET_QUESTIONS_BY_CATEGORY } from 'context/Assessment/gql'
import { AssessmentContext } from 'context/Assessment'
import { MicroServiceContext } from 'context/MicroService'
import CategoryProgressBar from './CategoryProgressBar'
import { getCategoryDescriptionBySlug } from 'utils/dashboardUtils'

interface Props {
  category: Category
}
const CategoryPaper = styled(Paper)<PaperProps>(() => ({
  width: '100%',
  marginBottom: '24px',
  minWidth: '540px',
  [theme.breakpoints.up('lg')]: {
    minHeight: '164px',
    display: 'flex',
    flexDirection: 'column'
  }
}))

const CategoryBox = ({ category }: Props) => {
  const { hasActiveAssessment, employerAssessmentData } =
    useContext(AssessmentContext)
  const { maestroClient } = useContext(MicroServiceContext)
  const [showProgress, setShowProgress] = useState(false)
  const navigate = useNavigate()
  const { data: employerAssessmentQuestions, loading } = useQuery(
    GET_QUESTIONS_BY_CATEGORY,
    {
      variables: {
        employerAssessmentCategoryId: category ? category.id : '',
        employerAssessmentId: employerAssessmentData?.id
      },
      client: maestroClient,
      fetchPolicy: 'cache-and-network'
    }
  )
  if (loading) return <></>
  const questions =
    employerAssessmentQuestions?.employerAssessmentQuestions &&
    employerAssessmentQuestions?.employerAssessmentQuestions.filter(
      (question: Question) => question.order !== null
    )

  const handleClick = () => {
    const activeQuestionIndex = questions.findIndex(
      (question: Question) => !question.isCompleted
    )
    if (activeQuestionIndex === -1) {
      navigate(`/equity-index/assessment/${category.slug}/1`)
    } else {
      navigate(
        `/equity-index/assessment/${category.slug}/${activeQuestionIndex + 1}`
      )
    }
  }
  const categoryNotStarted = Boolean(
    category.progress === 0 || !hasActiveAssessment
  )
  const categoryCompleted = category.isCompleted
  const categoryStatus = categoryNotStarted
    ? CategoryStatus.NOT_STARTED
    : categoryCompleted
    ? CategoryStatus.COMPLETE
    : CategoryStatus.STARTED

  const buttonStatus =
    categoryStatus === CategoryStatus.NOT_STARTED
      ? 'Start section'
      : categoryStatus === CategoryStatus.STARTED
      ? 'Continue section'
      : 'Revise section'

  // Get Category Description from slug
  const categoryDescription: string = getCategoryDescriptionBySlug(
    category.slug
  )

  return (
    <CategoryPaper variant='outlined'>
      <Box
        sx={{
          display: 'flex',
          [theme.breakpoints.down('lg')]: {
            flexDirection: 'column'
          }
        }}
      >
        <Grid lg={4} xs={12} item>
          <Typography
            color={
              hasActiveAssessment
                ? CATEGORY_TEXT_COLOR[category.name]
                : Color.grey500
            }
            variant='h6'
            marginBottom={3}
          >
            {category.name.replace('&', 'and')}
          </Typography>
          <Typography
            variant='body2'
            marginRight={4}
            color={hasActiveAssessment ? Color.black : Color.grey500}
          >
            {categoryDescription}
          </Typography>
        </Grid>
        <Grid
          lg={5}
          xs={12}
          item
          sx={{
            [theme.breakpoints.up('lg')]: {
              borderRight: `1px solid ${Color.border}`,
              borderLeft: `1px solid ${Color.border}`
            },
            [theme.breakpoints.down('lg')]: {
              padding: '32px',
              marginBottom: '8px'
            }
          }}
        >
          <Typography
            variant='subtitle2'
            marginBottom={4}
            color={hasActiveAssessment ? Color.black : Color.grey500}
          >
            {`${category.progress ? category.progress : 0}% COMPLETE`}
          </Typography>
          <CategoryProgressBar
            categoryCompleted={categoryCompleted}
            hasActiveAssessment={hasActiveAssessment}
            category={category}
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={3}
          sx={{
            height: 101,
            [theme.breakpoints.up('lg')]: {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'flex-end'
            },
            [theme.breakpoints.down('lg')]: {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }
          }}
        >
          <Button
            variant='contained'
            onClick={handleClick}
            disabled={!hasActiveAssessment}
            disableRipple
            endIcon={
              <ArrowCircleRightIcon
                style={{ width: 16, height: 16, marginLeft: '8px' }}
              />
            }
            sx={{
              width: '80%',
              maxWidth: 225,
              height: 40,
              whiteSpace: 'nowrap',
              '&:hover': {
                backgroundColor: '#050B0E'
              }
            }}
          >
            {buttonStatus}
          </Button>
          <Button
            variant='outlined'
            disableRipple
            disabled={!hasActiveAssessment}
            endIcon={showProgress ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            onClick={() => setShowProgress(!showProgress)}
            sx={{
              width: '80%',
              maxWidth: 225,
              height: 40,
              whiteSpace: 'nowrap',
              '&:hover': {
                backgroundColor: 'transparent'
              }
            }}
          >
            {showProgress ? 'Hide progress' : 'Show progress'}
          </Button>
        </Grid>
      </Box>
      {showProgress && (
        <CategoryQuestionList questions={questions} slug={category.slug} />
      )}
    </CategoryPaper>
  )
}
export default CategoryBox
