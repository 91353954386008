import React, { useEffect, useContext, useState, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Paper, PaperProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { theme } from 'components'
import { useLazyQuery } from '@apollo/client'
import {
  GET_QUESTION,
  GET_QUESTION_IDS_BY_CATEGORY,
  GET_EMPLOYER_QUESTION
} from 'context/Assessment/gql'
import { AssessmentContext } from 'context/Assessment'
import { Answer, QuestionState, Question } from 'model/assessment'
import { QUESTION_STATE_INITIAL_VALUES, INITIAL_QUESTION } from './constant'
import BreadcrumbHeader from './BreadcrumbHeader'
import QuestionDetails from './QuestionDetailsContainer'
import QuestionNavigation from './QuestionNavigation'
import FollowUpBanner from './FollowUpBanner'
import { MicroServiceContext } from 'context/MicroService'

const QuestionPageContainer = styled(Paper)<PaperProps>(() => ({
  padding: '64px 104px',
  [theme.breakpoints.up('sm')]: {
    minHeight: '745px'
  },
  [theme.breakpoints.up('md')]: {
    minHeight: '720px'
  },
  [theme.breakpoints.up('lg')]: {
    minHeight: '685px'
  },
  [theme.breakpoints.up('xl')]: {
    minHeight: '685px'
  }
}))

interface IProps {
  setHideSidebar: (hideSidebar: boolean) => void
  setAnswerSubmitted: React.Dispatch<React.SetStateAction<boolean>>
  answerSubmitted: boolean
}

const AssessmentQuestion = ({
  setHideSidebar,
  setAnswerSubmitted,
  answerSubmitted
}: IProps) => {
  const { maestroClient } = useContext(MicroServiceContext)
  const { slug, questionOrder } = useParams()
  const { employerAssessmentData } = useContext(AssessmentContext)
  const [closeFollowUpBanner, setCloseFollowUpBanner] = useState(false)
  const [isFollowup, setIsFollowup] = useState(false)
  const [followupId, setFollowupId] = useState<string[]>([])
  const [categoryQuestions, setCategoryQuestions] = useState<Question[]>([])
  const [lastFollowupAnswers, setLastFollowupAnswers] = useState<Answer[]>()

  const [questionState, setQuestionState] = useReducer(
    (state: QuestionState, action: any) => {
      switch (action.type) {
        case 'saveCurrentSelection':
          return {
            questionsToSubmit: [...state.questionsToSubmit, action.value],
            currentQuestionSelectedIds: [],
            currentQuestionHasFollowup: false
          }
        case 'resetQuestionState':
          return {
            questionsToSubmit: [],
            currentQuestionSelectedIds: [],
            currentQuestionHasFollowup: false
          }
        case 'removeLastQuestionToSubmit':
          return {
            questionsToSubmit: [...state.questionsToSubmit.slice(0, -1)],
            currentQuestionSelectedIds: [
              ...state.questionsToSubmit[state.questionsToSubmit.length - 1]
                .selectedAnswerIds
            ],
            currentQuestionHasFollowup: false
          }
        default:
          return { ...state, [action.index]: action.value }
      }
    },
    QUESTION_STATE_INITIAL_VALUES
  )

  const currentCategory =
    employerAssessmentData.employerAssessmentCategories.find(
      category => category.slug === slug
    )

  const [loadingCategoryQuestionIds, { data: employerAssessmentQuestions }] =
    useLazyQuery(GET_QUESTION_IDS_BY_CATEGORY, {
      variables: {
        employerAssessmentCategoryId: currentCategory?.id,
        employerAssessmentId: employerAssessmentData.id
      },
      client: maestroClient,
      fetchPolicy: 'cache-and-network',
      onCompleted: () => {
        setCategoryQuestions(
          employerAssessmentQuestions?.employerAssessmentQuestions
        )
      }
    })

  const currentQuestionId = categoryQuestions[Number(questionOrder) - 1]?.id

  const [loadEmployerQuestion, { data: question }] = useLazyQuery(
    GET_EMPLOYER_QUESTION,
    {
      variables: {
        id: currentQuestionId
      },
      client: maestroClient,
      fetchPolicy: 'cache-and-network'
    }
  )

  const [
    loadingFollowupQuestion,
    { data: followupQuestion = INITIAL_QUESTION }
  ] = useLazyQuery(GET_QUESTION, {
    variables: {
      assessmentQuestionId:
        followupId[questionState.questionsToSubmit.length - 1]
    },
    client: maestroClient,
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (currentCategory?.id && employerAssessmentData.id)
      loadingCategoryQuestionIds()
  }, [
    currentCategory?.id,
    employerAssessmentData?.id,
    loadingCategoryQuestionIds
  ])

  useEffect(() => {
    if (currentQuestionId !== '' && currentQuestionId) {
      loadEmployerQuestion()
    }
  }, [loadEmployerQuestion, currentQuestionId, questionOrder, answerSubmitted])

  useEffect(() => {
    if (followupId.length) {
      loadingFollowupQuestion()
    }
  }, [loadingFollowupQuestion, followupId.length])

  const currentQuestion = isFollowup
    ? followupQuestion.assessmentQuestion
    : question?.employerAssessmentQuestion

  useEffect(() => {
    setHideSidebar(true)
    return () => {
      setHideSidebar(false)
    }
  }, [setHideSidebar])

  useEffect(() => {
    setCloseFollowUpBanner(false)
    if (currentQuestion && !isFollowup) {
      //If currentQuestion has followupQuestions w/ previously selected answers
      if (
        currentQuestion.followupQuestions[0]?.selectedAnswerIds.length > 0 &&
        question?.employerAssessmentQuestion.order === Number(questionOrder)
      ) {
        const lastFollowupQuestion =
          currentQuestion.followupQuestions[
            currentQuestion.followupQuestions.length - 1
          ]
        const formattedQuestionsToSubmit = currentQuestion.followupQuestions
          .slice(0, -1)
          .map((question: Question) => {
            return { question, selectedAnswerIds: question.selectedAnswerIds }
          })
        setQuestionState({
          index: 'currentQuestionSelectedIds',
          value: lastFollowupQuestion.selectedAnswerIds
        })
        setQuestionState({
          index: 'questionsToSubmit',
          value: [
            {
              question: currentQuestion,
              selectedAnswerIds: currentQuestion.selectedAnswerIds
            },
            ...formattedQuestionsToSubmit
          ]
        })
        const currentFollowupIds: string[] =
          currentQuestion.followupQuestions.map(
            (question: any) => question.assessmentQuestionId
          )
        setLastFollowupAnswers(lastFollowupQuestion.selectedAnswerIds)
        setFollowupId(currentFollowupIds)
        setIsFollowup(true)
        //Override questionState, if currentQuestion has previously selected answers
      } else if (questionState.currentQuestionSelectedIds.length) {
        setQuestionState({
          index: 'currentQuestionSelectedIds',
          value: currentQuestion?.selectedAnswerIds
        })
        //If nothing in questionState, set currentQuestionSelectedIds to currentQuestion.selectedAnswerIds
      } else {
        setQuestionState({
          index: 'currentQuestionSelectedIds',
          value: currentQuestion?.selectedAnswerIds.filter((id: string) => id)
        })
      }
    }
    // eslint-disable-next-line
  }, [question?.employerAssessmentQuestion?.id])

  const questionHasFollowup = currentQuestion?.assessmentAnswers.find(
    (answer: Answer) => answer.hasFollowupQuestion
  )

  return (
    <Grid
      container
      flexDirection='column'
      flexWrap='nowrap'
      margin={'auto'}
      maxWidth='1230px'
    >
      <BreadcrumbHeader
        categoryTitle={currentCategory ? currentCategory.name : ''}
      />
      {currentQuestion && (
        <QuestionPageContainer variant='outlined'>
          {questionHasFollowup && !closeFollowUpBanner && (
            <FollowUpBanner setCloseFollowUpBanner={setCloseFollowUpBanner} />
          )}
          <QuestionDetails
            questionOrder={questionOrder as string}
            question={currentQuestion}
            questionState={questionState}
            setQuestionState={setQuestionState}
            isFollowup={isFollowup}
            setIsFollowup={setIsFollowup}
            setFollowupId={setFollowupId}
          />
          <Grid
            item
            xs={12}
            sx={{ borderTop: '1px solid #000000', margin: '45px 0 16px 0' }}
          />
          <QuestionNavigation
            questionId={currentQuestion.id}
            question={currentQuestion}
            prevSelectedAnswerIds={
              isFollowup
                ? lastFollowupAnswers
                : currentQuestion.selectedAnswerIds?.filter((id: string) => id)
                ? currentQuestion.selectedAnswerIds?.filter((id: string) => id)
                : []
            }
            categorySlug={slug as string}
            currentOrder={questionOrder as string}
            totalNumberOfQuestions={
              categoryQuestions?.length ? categoryQuestions.length : 0
            }
            questionState={questionState}
            setQuestionState={setQuestionState}
            setIsFollowup={setIsFollowup}
            isFollowup={isFollowup}
            followupId={followupId}
            setFollowupId={setFollowupId}
            setAnswerSubmitted={setAnswerSubmitted}
          />
        </QuestionPageContainer>
      )}
    </Grid>
  )
}

export default AssessmentQuestion
