import React, { ReactNode } from 'react'
import { Paper } from '@mui/material'
import { Color } from 'components'

interface Props {
  children: ReactNode
  dense?: boolean
}

const DescriptionBanner = ({ children, dense }: Props) => {
  return (
    <Paper
      sx={{
        padding: dense ? '16px' : '20px',
        marginTop: 3,
        borderRadius: 2,
        bgcolor: Color.eggplant100
      }}
      elevation={0}
    >
      {children}
    </Paper>
  )
}
export default DescriptionBanner
