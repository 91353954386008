import { Components, Icons } from 'importHandler'

export const {
  ThemeInheritor,

  theme,
  Color,
  InfoBanner,
  UserAvatar,
  Table,
  TableContext,
  TableContextProvider,
  Paper,
  Dialog,
  TextField,
  DialogWithoutAction,

  Breadcrumbs
} = Components

export const { ArrowLeftIcon, ArrowRightIcon, ArrowCircleRightIcon } = Icons
