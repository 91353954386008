import React from 'react'
import { theme, Color } from 'components'
import { Box, Typography, Paper, PaperProps } from '@mui/material'
import DevelopmentStageChart from './DevelopmentStageChart'
import { styled } from '@mui/material/styles'

interface IProps {
  acquiredScore?: number
  noAssessmentsCompleted: boolean
}
interface DevelopmentContainerProps extends PaperProps {
  noAssessmentsCompleted: boolean
}

const DevelopmentStageContainer = styled(Paper, {
  shouldForwardProp: prop => prop !== 'noAssessmentsCompleted'
})<DevelopmentContainerProps>(({ noAssessmentsCompleted }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  minHeight: noAssessmentsCompleted ? '330px' : '384px',
  border: '1px solid #E5E5E5',
  borderRadius: '8px',
  padding: '40px 40px 50px 40px',
  [theme.breakpoints.down('md')]: {
    padding: '40px 20px 50px 20px'
  }
}))

const DevelopmentStage = ({
  acquiredScore,
  noAssessmentsCompleted
}: IProps) => {
  let stage = 'Discovering'
  if (acquiredScore && acquiredScore / 100 > 0.75) {
    stage = 'Leading'
  } else if (acquiredScore && acquiredScore / 100 > 0.5) {
    stage = 'Engaged'
  } else if (acquiredScore && acquiredScore / 100 > 0.25) {
    stage = 'Aware'
  }

  return (
    <DevelopmentStageContainer
      noAssessmentsCompleted={noAssessmentsCompleted}
      variant='outlined'
    >
      <Box>
        <Typography
          variant='h6'
          sx={{
            fontSize: '16px',
            color: noAssessmentsCompleted ? '#ACADAF' : Color.black,
            letterSpacing: '0.14em'
          }}
        >
          DEVELOPMENT STAGE
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <Typography
            variant='h6'
            sx={{
              fontSize: '16px',
              color: noAssessmentsCompleted ? '#ACADAF' : Color.black
            }}
          >
            Stage:
          </Typography>
          <Typography
            variant='body1'
            sx={{ fontSize: '16px', color: Color.black, marginLeft: '4px' }}
          >
            {!noAssessmentsCompleted && stage}
          </Typography>
        </Box>
      </Box>
      <DevelopmentStageChart
        acquiredScore={acquiredScore}
        noAssessmentsCompleted={noAssessmentsCompleted}
      />
    </DevelopmentStageContainer>
  )
}

export default DevelopmentStage
