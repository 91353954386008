import React from 'react'
import { Paper, PaperProps, Typography, Grid, GridProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { theme } from 'components/SharedComponents'
import QuarterTotalScoreChart from './QuarterTotalScoreChart'
import { EmployerAssessmentHistory } from 'model/assessment'

const QuarterTotalScoreContainer = styled(Paper)<PaperProps>(() => ({
  width: '100%',
  height: '460px',
  display: 'flex',
  flexDirection: 'column',
  padding: '40px 78px 46px 78px',
  [theme.breakpoints.down(1370)]: {
    padding: '40px 40px 46px 40px'
  },
  [theme.breakpoints.down('lg')]: {
    padding: '40px 78px 46px 78px'
  },
  [theme.breakpoints.down(1000)]: {
    padding: '40px 40px 46px 40px'
  }
}))

const SpaceAroundGrid = styled(Grid)<GridProps>(() => ({
  display: 'flex',
  justifyContent: 'space-around'
}))

const BorderTopGrid = styled(Grid)<GridProps>(() => ({
  borderTop: '2px solid #E5E5E5',
  paddingTop: '4px'
}))

interface IProps {
  employerAssessmentHistory: EmployerAssessmentHistory
  noAssessmentsCompleted: boolean
  myFirstQuarter: number
}

const developmentStages = ['Leading', 'Engaged', 'Aware', 'Discovering']

const QuarterTotalScoreProgress = ({
  employerAssessmentHistory,
  noAssessmentsCompleted,
  myFirstQuarter
}: IProps) => {
  // Created new EmployerAssessmentHistory variable to remove the __typename key that gets passed from response
  let cleanEmployerAssessmentHistory: EmployerAssessmentHistory = {
    firstQuarter: null,
    secondQuarter: null,
    thirdQuarter: null,
    fourthQuarter: null
  }

  if (employerAssessmentHistory) {
    cleanEmployerAssessmentHistory = {
      firstQuarter: employerAssessmentHistory.firstQuarter,
      secondQuarter: employerAssessmentHistory.secondQuarter,
      thirdQuarter: employerAssessmentHistory.thirdQuarter,
      fourthQuarter: employerAssessmentHistory.fourthQuarter
    }
  }
  const allScores = Object.values(cleanEmployerAssessmentHistory)

  return (
    <QuarterTotalScoreContainer variant='outlined'>
      <Grid container>
        <Grid item xs={4} md={5} lg={5.5} />
        <Grid item xs={8} md={7} lg={6.5}>
          <Typography
            variant='h6'
            sx={{
              fontSize: '16px',
              letterSpacing: '0.14em',
              marginBottom: '65px',
              whiteSpace: 'noWrap',
              color: noAssessmentsCompleted ? '#ACADAF' : 'black'
            }}
          >
            TOTAL SCORE BY QUARTER
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={3} sx={{ height: '259px', marginRight: 'auto' }}>
          {developmentStages.map((stage, idx) => (
            <Typography
              key={idx}
              variant='body2'
              sx={{
                fontWeight: 600,
                lineHeight: '20px',
                borderTop: '2px solid #E5E5E5',
                paddingTop: '6px',
                marginBottom: '39px',
                color: noAssessmentsCompleted ? '#ACADAF' : 'black'
              }}
            >
              {stage}
            </Typography>
          ))}
        </Grid>
        <SpaceAroundGrid item xs={8}>
          {allScores.map((score, idx) => (
            <QuarterTotalScoreChart
              key={idx}
              acquiredScore={score ? score.acquiredScore : 0}
              beforeFirstQuarter={idx < myFirstQuarter}
              noAssessmentsCompleted={noAssessmentsCompleted}
            />
          ))}
        </SpaceAroundGrid>
      </Grid>
      <BorderTopGrid container>
        <Grid item xs={4} />
        <SpaceAroundGrid item xs={8}>
          <Typography
            variant='body2'
            sx={{
              color:
                myFirstQuarter >= 1
                  ? '#747779'
                  : noAssessmentsCompleted
                  ? '#ACADAF'
                  : '#050B0E'
            }}
          >
            Q1
          </Typography>
          <Typography
            variant='body2'
            sx={{
              color:
                myFirstQuarter >= 2
                  ? '#747779'
                  : noAssessmentsCompleted
                  ? '#ACADAF'
                  : '#050B0E'
            }}
          >
            Q2
          </Typography>
          <Typography
            variant='body2'
            sx={{
              color:
                myFirstQuarter >= 3
                  ? '#747779'
                  : noAssessmentsCompleted
                  ? '#ACADAF'
                  : '#050B0E'
            }}
          >
            Q3
          </Typography>
          <Typography
            variant='body2'
            sx={{ color: noAssessmentsCompleted ? '#ACADAF' : '#050B0E' }}
          >
            Q4
          </Typography>
        </SpaceAroundGrid>
      </BorderTopGrid>
    </QuarterTotalScoreContainer>
  )
}

export default QuarterTotalScoreProgress
