import React from 'react'
import { Grid, GridProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Question, QuestionState, UpdateQuestionInput } from 'model/assessment'
import ActiveQuestion from './ActiveQuestion'
import InactiveQuestion from './InactiveQuestion'

const QuestionDetailsContainer = styled(Grid)<GridProps>(() => ({
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'row'
}))

interface IProps {
  question: Question
  questionOrder: string
  questionState: QuestionState
  setQuestionState: React.Dispatch<any>
  isFollowup: boolean
  setIsFollowup: React.Dispatch<any>
  setFollowupId: React.Dispatch<any>
}

const QuestionDetails = ({
  question,
  questionOrder,
  questionState,
  setQuestionState,
  isFollowup,
  setFollowupId,
  setIsFollowup
}: IProps) => {
  return (
    <QuestionDetailsContainer container>
      {questionState.questionsToSubmit?.map(
        (questionToSubmit: UpdateQuestionInput, index: number) => {
          return (
            <InactiveQuestion
              questionToSubmit={questionToSubmit}
              questionState={questionState}
              setQuestionState={setQuestionState}
              setIsFollowup={setIsFollowup}
              key={index}
            />
          )
        }
      )}
      <ActiveQuestion
        question={question}
        questionOrder={questionOrder}
        questionState={questionState}
        setQuestionState={setQuestionState}
        isFollowup={isFollowup}
        setFollowupId={setFollowupId}
      />
    </QuestionDetailsContainer>
  )
}

export default QuestionDetails
