import React, { useState, useEffect, useRef } from 'react'
import { Box, BoxProps } from '@mui/material'
import { styled } from '@mui/material/styles'

const ChartBackground = styled(Box)<BoxProps>(() => ({
  height: '100%',
  width: '12px',
  borderRadius: '8px 8px 0px 0px',
  display: 'flex',
  alignItems: 'flex-end'
}))

const ChartForeground = styled(Box)<BoxProps>(() => ({
  width: '12px',
  borderRadius: '8px 8px 0px 0px'
}))

const colors = [
  'linear-gradient(180deg, #B83282 0%, #E28DC0 107.75%)',
  'linear-gradient(178.37deg, #430DA8 1.38%, #956BDB 84.24%)',
  'linear-gradient(176.49deg, #068D8E 2.89%, #6AC2C4 76.55%)',
  'linear-gradient(177.59deg, #375FC6 2.02%, #829FE9 85.14%)'
]

const backgroundColors = ['#F8EBF3', '#ECE7F6', '#E6F4F4', '#E1EAFF']

interface IProps {
  idx: number
  acquiredScore?: number
  isVisible: boolean
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
  downloadClicked: boolean
  setGraphComplete: React.Dispatch<React.SetStateAction<boolean>>
  beforeFirstQuarter: boolean
  noAssessmentsCompleted: boolean
}

const QuarterCategoryProgressChart = ({
  idx,
  acquiredScore,
  isVisible,
  setIsVisible,
  downloadClicked,
  setGraphComplete,
  beforeFirstQuarter,
  noAssessmentsCompleted
}: IProps) => {
  const [score, setScore] = useState(0)
  const myRef = useRef<HTMLDivElement>(null!)

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      const entry = entries[0]
      setIsVisible(entry.isIntersecting)
    })
    observer.observe(myRef.current)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isVisible) {
      if (downloadClicked && acquiredScore) {
        setScore(acquiredScore * 4)
        if (score === acquiredScore * 4) {
          setGraphComplete(true)
        }
      } else {
        if (acquiredScore && score < acquiredScore * 4) {
          const countUp = setInterval(() => {
            setScore(prevScore => prevScore + 1)
          }, 15)
          return () => clearInterval(countUp)
        }
      }
    }
  }, [acquiredScore, score, isVisible, downloadClicked, setGraphComplete])

  return (
    <Box sx={{ height: '100%' }} ref={myRef}>
      <ChartBackground
        sx={{
          backgroundColor:
            beforeFirstQuarter || noAssessmentsCompleted
              ? '#F7F7F7'
              : backgroundColors[idx]
        }}
      >
        <ChartForeground
          sx={{
            background: colors[idx],
            height: `${score}%`
          }}
        />
      </ChartBackground>
    </Box>
  )
}

export default QuarterCategoryProgressChart
