import { Color } from 'components'

export const CATEGORY_TEXT_COLOR: { [key: string]: string } = {
  'Defining & Tracking': Color.raspberry400,
  'Attracting & Sourcing': Color.eggplant400,
  'Interviewing & Engaging': Color.turquoise400,
  'Onboarding & Advancing': Color.iris400
}
export const CATEGORY_BAR_BACKGROUND: { [key: string]: string } = {
  'Defining & Tracking': Color.raspberry100,
  'Attracting & Sourcing': Color.eggplant100,
  'Interviewing & Engaging': Color.turquoise100,
  'Onboarding & Advancing': Color.iris100
}

export const CATEGORY_BAR_GRADIENT: { [key: string]: string } = {
  'Defining & Tracking': 'linear-gradient(270deg, #B83282 50%, #E28DC0 99.61%)',
  'Attracting & Sourcing':
    'linear-gradient(270.04deg, #430DA8 50.04%, #956BDB 94.1%)',
  'Interviewing & Engaging':
    'linear-gradient(270.03deg, #068D8E 50.03%, #6AC2C4 86.68%)',
  'Onboarding & Advancing':
    'linear-gradient(270.03deg, #375FC6 50.03%, #829FE9 92.29%)'
}
