import React, { useContext } from 'react'
import { Grid, Typography, Paper } from '@mui/material'
import { InfoBanner } from 'components'
import MiniCard from './MiniCard'
import { GoalManagerContext } from 'context/GoalManager'

const MiniDashboard = () => {
  const { goalCount, isBannerOpen, setIsBannerOpen } =
    useContext(GoalManagerContext)

  return (
    <Paper sx={{ padding: '32px', marginTop: '16px' }} variant='outlined'>
      <Typography variant='h7'>TOTAL ACTIVE GOALS BY CATEGORY</Typography>
      {isBannerOpen && (
        <InfoBanner
          sx={{ marginTop: 2 }}
          content='It’s best to have about 2–3 goals per category each quarter. You’re on track!'
          fullWidth
          onClose={() => {
            setIsBannerOpen(false)
          }}
        ></InfoBanner>
      )}
      <Grid container item marginTop='8px' spacing={2}>
        <MiniCard
          goalCategory="defining_tracking"
        ></MiniCard>
        <MiniCard
          goalCategory="attracting_sourcing"
        ></MiniCard>
        <MiniCard
          goalCategory="interviewing_engaging"
        ></MiniCard>
        <MiniCard
          goalCategory="onboarding_advancing"
        ></MiniCard>
        <Grid item xs={2.4}>
          <Paper variant='outlined' sx={{ padding: '16px', height: '100%' }}>
            <Typography variant='body16'>
              <Typography variant='body16' component='div'>
                Total
              </Typography>{' '}
              active goals
            </Typography>
            <Typography variant='h2' marginTop='24px'>
              {goalCount.all.active}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  )
}
export default MiniDashboard
