import { AssessmentData, EmployerAssessmentData } from 'model/assessment'

export const INITIAL_EMPLOYER_ASSESSMENT: EmployerAssessmentData = {
  employerAssessment: {
    id: '',
    employerId: '',
    versionName: '',
    totalScore: 0,
    acquiredScore: 0,
    progress: 0,
    status: '',
    createdAt: '',
    updatedAt: '',
    employerAssessmentCategories: [
      {
        id: '',
        categoryId: '',
        name: '',
        slug: '',
        description: '',
        totalScore: 0,
        acquiredScore: 0,
        progress: 0,
        isCompleted: false,
        createdAt: '',
        updatedAt: ''
      }
    ]
  }
}

export const INITIAL_ASSESSMENT: AssessmentData = {
  assessment: {
    id: '',
    versionName: '1',
    totalScore: 0,
    activeStatus: '',
    createdAt: '',
    updatedAt: '',
    categories: [
      {
        id: '',
        name: '',
        slug: '',
        description: '',
        totalScore: 0,
        createdAt: '',
        updatedAt: ''
      },
      {
        id: '',
        name: '',
        slug: '',
        description: '',
        totalScore: 0,
        createdAt: '',
        updatedAt: ''
      },
      {
        id: '',
        name: '',
        slug: '',
        description: '',
        totalScore: 0,
        createdAt: '',
        updatedAt: ''
      },
      {
        id: '',
        name: '',
        slug: '',
        description: '',
        totalScore: 0,
        createdAt: '',
        updatedAt: ''
      }
    ]
  }
}
