import React from 'react'
import { Paper, PaperProps, Typography, Grid, Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { UpdateQuestionInput, Answer, QuestionState } from 'model/assessment'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'

const QuestionContainer = styled(Paper)<PaperProps>(() => ({
  width: '100%',
  padding: '32px',
  marginBottom: '24px'
}))

interface IProps {
  questionToSubmit: UpdateQuestionInput
  setQuestionState: React.Dispatch<any>
  setIsFollowup: React.Dispatch<any>
  questionState: QuestionState
}

const InactiveQuestion = ({
  questionToSubmit,
  setQuestionState,
  setIsFollowup,
  questionState
}: IProps) => {
  const question = questionToSubmit.question
  const selectedAnswerIds = questionToSubmit.selectedAnswerIds
  const questionLabel = question?.order ? `0${question.order}`.slice(-2) : ''

  return (
    <>
      <Grid item xs={12} lg={7}>
        <QuestionContainer variant='outlined'>
          <Grid container justifyContent={'space-between'}>
            <Typography
              variant='subtitle1'
              fontWeight={700}
              letterSpacing={'0.12em'}
              textTransform='uppercase'
              marginBottom={2}
            >
              {questionLabel ? `Question ${questionLabel}` : 'Follow-up'}
            </Typography>
            <Button
              sx={{
                minWidth: '40px',
                height: '28px',
                fontSize: '14px',
                fontWeight: 600,
                padding: '4px 8px',
                fontFamily: 'Roboto'
              }}
              variant='contained'
              color='secondary'
              onClick={() => {
                if (question.__typename === 'EmployerAssessmentQuestion') {
                  setIsFollowup(false)
                  setQuestionState({ type: 'resetQuestionState', value: '' })
                  setQuestionState({
                    index: 'currentQuestionSelectedIds',
                    value: Array.isArray(
                      questionState.questionsToSubmit[0].selectedAnswerIds
                    )
                      ? questionState.questionsToSubmit[0].selectedAnswerIds
                      : [questionState.questionsToSubmit[0].selectedAnswerIds]
                  })
                } else {
                  setIsFollowup(true)
                  setQuestionState({
                    type: 'removeLastQuestionToSubmit',
                    value: ''
                  })
                }
              }}
            >
              Edit
            </Button>
          </Grid>
          <Typography variant='h5' marginBottom={2} width='70%'>
            {question?.questionText}
          </Typography>
          {question?.assessmentAnswers.map((answer: Answer, index: number) => {
            return (
              selectedAnswerIds.includes(answer.id) && (
                <Grid
                  container
                  key={index}
                  width='70%'
                  sx={{
                    flexWrap: 'nowrap',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <CheckRoundedIcon sx={{ fontSize: '17px' }} />
                  <Typography variant='body2' color='black' marginLeft={1}>
                    {answer.answerText}
                  </Typography>
                </Grid>
              )
            )
          })}
        </QuestionContainer>
      </Grid>
    </>
  )
}

export default InactiveQuestion
