import React, { useState, useEffect } from 'react'
import { Grid, Typography, Box, Button, Stack } from '@mui/material'
import { Divider, Color } from 'components'
import { goalNoteCache } from 'utils/goalManager'
import ActiveNote from './ActiveNote'
import he from 'he'

interface Props {
  id: string
  savedNote?: string
  readonly?: boolean
}

const Notes = ({ id, savedNote, readonly }: Props) => {
  const [isEditing, setIsEditing] = useState(false)

  const decodedSavedNote = he.decode(savedNote || '')
  const unsavedNote = goalNoteCache.getItem(id)

  useEffect(() => {
    if (unsavedNote) {
      setIsEditing(true)
    } else {
      setIsEditing(false)
    }
  }, [unsavedNote, id])

  return (
    <Grid container>
      <Typography variant='formLabel' letterSpacing={'.14em'}>
        NOTES
      </Typography>
      <Divider />
      {readonly ? (
        <Grid item xs={12} marginTop={2}>
          {decodedSavedNote && (
            <Typography
              variant='body16Light'
              dangerouslySetInnerHTML={{
                __html: decodedSavedNote
              }}
            ></Typography>
          )}
          {!decodedSavedNote && (
            <Typography variant='body16Light'>
              There are no notes for this goal.
            </Typography>
          )}
        </Grid>
      ) : (
        <Grid item xs={12} marginTop={2}>
          {!decodedSavedNote && !isEditing && !unsavedNote && (
            <Box
              border={`1px solid ${Color.grey300}`}
              borderRadius='8px'
              padding='12px'
              onClick={e => {
                e.stopPropagation()
                setIsEditing(true)
              }}
            >
              <Typography variant='body16Light'>Add a note...</Typography>
            </Box>
          )}
          {(decodedSavedNote || unsavedNote) && !isEditing && (
            <Stack spacing={3}>
              <Typography
                variant='body16Light'
                dangerouslySetInnerHTML={{
                  __html: decodedSavedNote || ''
                }}
              ></Typography>
              <Button
                sx={{ alignSelf: 'flex-start', padding: '8px 12px' }}
                variant='contained'
                color='secondary'
                onClick={e => {
                  e.stopPropagation()
                  setIsEditing(true)
                }}
              >
                Edit notes
              </Button>
            </Stack>
          )}
          {isEditing && (
            <ActiveNote
              id={id}
              onClose={() => {
                goalNoteCache.removeItem(id)
                setIsEditing(false)
              }}
              savedNote={decodedSavedNote}
              unsavedNote={unsavedNote}
            />
          )}
        </Grid>
      )}
    </Grid>
  )
}
export default Notes
