import React, { createContext, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import {
  GET_ASSESSMENT,
  GET_EMPLOYER_ASSESSMENT,
  UPDATE_QUESTION,
  SUBMIT_ASSESSMENT
} from './gql'
import { Assessment, EmployerAssessment } from 'model/assessment'
import { INITIAL_ASSESSMENT, INITIAL_EMPLOYER_ASSESSMENT } from './constants'
import { MicroServiceContext } from 'context/MicroService'
import { AccessContext } from 'context/AccessContext'

type AssessmentContextData = {
  employerAssessmentData: EmployerAssessment
  assessmentData: Assessment
  hasActiveAssessment?: boolean
  assessmentCompleted?: boolean
  onUpdateQuestion: (input: any, navigateTo?: string) => void
  onSubmitAssessment: (input: any, navigateTo?: string) => void
}

export const AssessmentContext = createContext<AssessmentContextData>({
  employerAssessmentData: INITIAL_EMPLOYER_ASSESSMENT.employerAssessment,
  assessmentData: INITIAL_ASSESSMENT.assessment,
  hasActiveAssessment: undefined,
  assessmentCompleted: undefined,
  onUpdateQuestion: () => {},
  onSubmitAssessment: () => {}
})

export const AssessmentProvider = ({ children }: any): JSX.Element => {
  const { maestroClient } = useContext(MicroServiceContext)
  const navigate = useNavigate()
  const { hasAccessToViewAssessment } = useContext(AccessContext)

  const { data: employerAssessment = INITIAL_EMPLOYER_ASSESSMENT } = useQuery(
    GET_EMPLOYER_ASSESSMENT,
    {
      client: maestroClient,
      fetchPolicy: 'cache-and-network'
    }
  )

  const { data: assessment = INITIAL_ASSESSMENT, loading } =
    useQuery(GET_ASSESSMENT)

  const [updateQuestion] = useMutation(UPDATE_QUESTION, {
    client: maestroClient,
    onCompleted: () => {
      console.log('Command dispatched')
    },
    onError: (e: any) => {
      console.log(e.message)
    }
  })
  const onUpdateQuestion = (input: any, navigateTo?: string) => {
    if (!hasAccessToViewAssessment) return
    updateQuestion(input).then(() => navigateTo && navigate(navigateTo))
  }

  const [submitAssessment] = useMutation(SUBMIT_ASSESSMENT, {
    client: maestroClient,
    onCompleted: () => {
      console.log('Assessment Submited')
    },
    onError: (e: any) => {
      console.log(e.message)
    }
  })
  const onSubmitAssessment = (input: any, navigateTo?: string) => {
    if (!hasAccessToViewAssessment) return
    submitAssessment(input).then(() => navigateTo && navigate(navigateTo))
  }
  if (loading) return <></>

  const employerAssessmentData = employerAssessment.employerAssessment
  const hasActiveAssessment = Boolean(employerAssessmentData?.id)
  const assessmentCompleted = Boolean(employerAssessmentData?.progress === 100)
  const assessmentData = assessment.assessment

  return (
    <AssessmentContext.Provider
      value={{
        employerAssessmentData: employerAssessmentData,
        assessmentData: assessmentData,
        hasActiveAssessment: hasActiveAssessment,
        assessmentCompleted: assessmentCompleted,
        onUpdateQuestion: onUpdateQuestion,
        onSubmitAssessment: onSubmitAssessment
      }}
    >
      {children}
    </AssessmentContext.Provider>
  )
}
