import React from 'react'
import {
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography
} from '@mui/material'
import { DividerLine } from '..'
import { Answer, QuestionState } from 'model/assessment'

interface SingleSelectProps {
  answers: Answer[]
  questionState: QuestionState
  setQuestionState: React.Dispatch<any>
  setFollowupId: React.Dispatch<any>
}

const SingleSelectQuestion = ({
  answers,
  questionState,
  setQuestionState,
  setFollowupId
}: SingleSelectProps) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const value = (e.target as HTMLInputElement).value
    const answerHasFollowup = answers.filter(
      answer => answer.id === value && answer.hasFollowupQuestion
    )
    if (questionState.currentQuestionSelectedIds.includes(value)) {
      setQuestionState({ index: 'currentQuestionSelectedIds', value: [] })
      setQuestionState({
        index: 'currentQuestionHasFollowup',
        value: false
      })
    } else {
      setQuestionState({ index: 'currentQuestionSelectedIds', value: value })
      setQuestionState({
        index: 'currentQuestionHasFollowup',
        value: answerHasFollowup.length > 0
      })
    }
    const followupIds =
      answerHasFollowup.length > 0
        ? answerHasFollowup[0]?.followupQuestionIds
        : []
    if (questionState.questionsToSubmit.length === 0) {
      setFollowupId(followupIds)
    }
  }
  return (
    <FormControl
      sx={{ padding: '0 32px', boxSizing: 'border-box', width: '100%' }}
    >
      <RadioGroup value={questionState.currentQuestionSelectedIds}>
        {answers.map(answer => (
          <Box key={answer.id} sx={{ width: '100%' }}>
            <DividerLine item xs={12} />
            <FormControlLabel
              control={
                <Radio
                  onClick={e => {
                    handleClick(e)
                    if (
                      answer.hasFollowupQuestion !==
                      questionState.currentQuestionHasFollowup
                    ) {
                      setFollowupId(answer?.followupQuestionIds)
                    }
                  }}
                  value={answer.id}
                  sx={{
                    marginRight: '20px',
                    padding: 0,
                    fontSize: '20px',
                    '& .MuiSvgIcon-root': {
                      height: '20px',
                      width: '20px'
                    }
                  }}
                />
              }
              label={
                <Typography variant='body2' color='black'>
                  {answer.answerText}
                </Typography>
              }
              sx={{
                margin: '8px 0',
                padding: '8px 0',
                width: '100%',
                '&:hover': {
                  backgroundColor: '#F7F7F7'
                }
              }}
            />
          </Box>
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default SingleSelectQuestion
