import React, { useContext, useState } from 'react'
import { Grid, Typography, Snackbar, SnackbarProps } from '@mui/material'
import { DashboardContext } from 'context/Dashboard'
import TotalAssessmentScore from './TotalAssessmentScore'
import DevelopmentStage from './DevelopmentStage'
import QuarterCategoryProgress from './QuarterCategoryProgress'
import QuarterTotalScoreProgress from './QuarterTotalScoreProgress'
import CategoryProgress from './CategoryProgress'
import {
  getPreviousAndCurrentScores,
  getUserFirstCompletedQuarter
} from 'utils/dashboardUtils'
import LegacyDataBanner from './LegacyDataBanner'
import FinishAssessmentBanner from './FinishAssessmentBanner'
import ClearIcon from '@mui/icons-material/Clear'
import { styled } from '@mui/material/styles'
import GoalsTable from './GoalsTable'
import { INITIAL_EMPLOYER_ASSESSMENT_HISTORY } from 'context/Dashboard'
import { AccessContext } from 'context/AccessContext'

interface IProps {
  isVisible: boolean
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
  downloadClicked: boolean
  setGraphComplete: React.Dispatch<React.SetStateAction<boolean>>
  noAssessmentsCompleted: boolean
}

export const StyledSnackbar = styled(Snackbar)<SnackbarProps>(() => ({
  backgroundColor: '#050B0E',
  color: 'white',
  borderRadius: '8px',
  boxShadow: '3px 3px 12px 2px rgba(155, 157, 159, 0.3)'
}))

const ScoreDashboard = ({
  isVisible,
  setIsVisible,
  downloadClicked,
  setGraphComplete,
  noAssessmentsCompleted
}: IProps) => {
  const { employerAssessmentHistory } = useContext(DashboardContext)
  const { hasAccessToViewDashboard } = useContext(AccessContext)
  const [previousScore, currentScore] = getPreviousAndCurrentScores(
    employerAssessmentHistory
  )
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const myFirstQuarter = getUserFirstCompletedQuarter(employerAssessmentHistory)

  const handleClose = () => {
    setSnackbarOpen(false)
  }

  const emptyCategoryScores =
    INITIAL_EMPLOYER_ASSESSMENT_HISTORY.firstQuarter
      ?.employerAssessmentCategories

  return (
    <Grid container id='score-dashboard' spacing={3} sx={{ marginTop: '-6px' }}>
      {noAssessmentsCompleted && hasAccessToViewDashboard && (
        <Grid item xs={12}>
          <FinishAssessmentBanner />
        </Grid>
      )}
      {hasAccessToViewDashboard && (
        <>
          <Grid item lg={4.5} xs={12}>
            <TotalAssessmentScore
              acquiredScore={currentScore && currentScore.acquiredScore}
              previousAcquiredScore={
                previousScore ? previousScore.acquiredScore : 0
              }
              previousQuarter={previousScore ? previousScore.quarter : 1}
              noAssessmentsCompleted={noAssessmentsCompleted}
            />
          </Grid>
          <Grid item lg={7.5} xs={12}>
            <QuarterTotalScoreProgress
              employerAssessmentHistory={employerAssessmentHistory}
              noAssessmentsCompleted={noAssessmentsCompleted}
              myFirstQuarter={myFirstQuarter}
            />
          </Grid>
          <Grid item xs={12}>
            <DevelopmentStage
              acquiredScore={currentScore && currentScore.acquiredScore}
              noAssessmentsCompleted={noAssessmentsCompleted}
            />
          </Grid>
          <Typography
            variant='h5'
            margin='24px 0 -8px 24px'
            color={noAssessmentsCompleted ? '#ACADAF' : 'black'}
          >
            Progress by Category
          </Typography>
          <Grid item xs={12}>
            <CategoryProgress
              categoryScores={
                currentScore
                  ? currentScore.employerAssessmentCategories
                  : emptyCategoryScores
              }
              noAssessmentsCompleted={noAssessmentsCompleted}
              isVisible={isVisible}
              setIsVisible={setIsVisible}
              downloadClicked={downloadClicked}
              setGraphComplete={setGraphComplete}
            />
          </Grid>
          {!noAssessmentsCompleted && (
            <>
              <Typography variant='h5' margin='24px 0 -8px 24px'>
                Category Progress by Quarter
              </Typography>
              <Grid item xs={12}>
                <QuarterCategoryProgress
                  employerAssessmentHistory={employerAssessmentHistory}
                  isVisible={isVisible}
                  setIsVisible={setIsVisible}
                  downloadClicked={downloadClicked}
                  setGraphComplete={setGraphComplete}
                  myFirstQuarter={myFirstQuarter}
                  noAssessmentsCompleted={noAssessmentsCompleted}
                />
              </Grid>
            </>
          )}
        </>
      )}
      <Typography
        variant='h5'
        margin='24px 0 -8px 24px'
        color={noAssessmentsCompleted ? '#ACADAF' : 'black'}
      >
        Completed Goals
      </Typography>
      <Grid item xs={12}>
        <GoalsTable noAssessmentsCompleted={noAssessmentsCompleted} />
      </Grid>
      {hasAccessToViewDashboard && (
        <>
          <Grid item xs={12}>
            <LegacyDataBanner setSnackbarOpen={setSnackbarOpen} />
          </Grid>
          <StyledSnackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            sx={{ padding: '12px' }}
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Typography sx={{ display: 'flex', alignItems: 'center' }}>
              Email Sent.
              <ClearIcon
                onClick={handleClose}
                sx={{
                  marginLeft: '20px',
                  fontSize: '16px',
                  '&:hover': { cursor: 'pointer' }
                }}
              />
            </Typography>
          </StyledSnackbar>
        </>
      )}
    </Grid>
  )
}

export default ScoreDashboard
