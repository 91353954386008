import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Grid, { GridProps } from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { CarouselSlide } from 'model/assessment'
import { styled } from '@mui/material/styles'
import Button, { ButtonProps } from '@mui/material/Button'
import { theme, ArrowCircleRightIcon } from 'components'
import { Typography } from '@mui/material'
import {
  CREATE_EMPLOYER_ASSESSMENT,
  CHECK_EMPLOYER_ASSESSMENT_STATUS
} from 'context/Dashboard/gql'
import { useQuery, useMutation } from '@apollo/client'
import { MicroServiceContext } from 'context/MicroService'
import { AccessContext } from 'context/AccessContext'

interface PageCarouselSlideProps {
  slide: CarouselSlide
  idx: number
}

const SlideGrid = styled(Grid)<GridProps>({
  height: '85%',
  minWidth: '100%',
  padding: '0 60px 0',
  justifyContent: 'center',
  alignItems: 'center',
  WebkitTransformStyle: 'preserve-3d',
  WebkitBackfaceVisibility: 'hidden'
})

const CarouselButton = styled(Button)<ButtonProps>({
  padding: '12px 16px',
  lineHeight: '24px',
  margin: '40px 0',
  [theme.breakpoints.up('lg')]: {
    width: '356px'
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '14px',
    width: '100%'
  }
})

const PageCarouselSlide = ({ slide, idx }: PageCarouselSlideProps) => {
  const { maestroClient } = useContext(MicroServiceContext)
  const { hasAccessToViewAssessment } = useContext(AccessContext)
  const navigate = useNavigate()
  const { data, loading } = useQuery(CHECK_EMPLOYER_ASSESSMENT_STATUS, {
    client: maestroClient
  })
  const [createEmployerAssessment] = useMutation(CREATE_EMPLOYER_ASSESSMENT, {
    client: maestroClient,
    onCompleted: () => {
      navigate('/equity-index/assessment')
    }
  })
  if (loading) return <></>
  const hasActiveAssessment = Boolean(data?.employerAssessment)
  const handleClick = async () => {
    if (hasActiveAssessment) {
      navigate('/equity-index/assessment')
    } else {
      await createEmployerAssessment()
    }
  }

  return (
    <SlideGrid container>
      <Grid
        sx={{
          padding: '20px'
        }}
        item
        xs={12}
        md={5}
      >
        <Box sx={{ maxWidth: '372px', marginTop: '80px' }}>
          <Typography variant='h2' marginBottom={2}>
            {slide.title}
          </Typography>
          <Typography variant='body2'>{slide.content}</Typography>
        </Box>
        <CarouselButton
          onClick={handleClick}
          variant='contained'
          disabled={!hasAccessToViewAssessment}
          endIcon={
            <ArrowCircleRightIcon
              style={{ width: 24, height: 24, marginLeft: '24px' }}
            />
          }
        >
          Start the Equity Index assessment
        </CarouselButton>
      </Grid>
      <Grid sx={{ padding: '20px' }} item xs={12} md={7}>
        <img
          style={{ width: idx < 3 ? '100%' : '115%' }}
          src={slide.image}
          alt={`slide for ${slide.title}`}
        />
      </Grid>
    </SlideGrid>
  )
}
export default PageCarouselSlide
