import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Box, ButtonProps, BoxProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Color } from 'components'
import { useMutation, useQuery } from '@apollo/client'
import {
  CREATE_EMPLOYER_ASSESSMENT,
  CHECK_EMPLOYER_ASSESSMENT_STATUS
} from 'context/Dashboard/gql'
import { MicroServiceContext } from 'context/MicroService'
import { AccessContext } from 'context/AccessContext'

const StartButtonContainer = styled(Box)<BoxProps>(() => ({
  backgroundColor: Color.blue50,
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 24,
  borderRadius: 8,
  height: '100%'
}))

const StartButton = styled(Button)<ButtonProps>(() => ({
  height: 60,
  width: 186,
  whiteSpace: 'nowrap'
}))

interface Props {
  setAssessmentStarted: React.Dispatch<React.SetStateAction<boolean>>
}

const AssessmentStartButton = ({ setAssessmentStarted }: Props) => {
  const { maestroClient } = useContext(MicroServiceContext)
  const { hasAccessToViewAssessment } = useContext(AccessContext)
  const navigate = useNavigate()

  const { data, loading } = useQuery(CHECK_EMPLOYER_ASSESSMENT_STATUS, {
    client: maestroClient
  })

  const [createEmployerAssessment] = useMutation(CREATE_EMPLOYER_ASSESSMENT, {
    client: maestroClient,
    onCompleted: () => {
      navigate('/equity-index/assessment')
    }
  })

  const hasActiveAssessment = Boolean(data?.employerAssessment)

  const handleClick = async () => {
    if (hasActiveAssessment) {
      navigate('/equity-index/assessment')
    } else {
      await createEmployerAssessment()
      await setAssessmentStarted(true)
    }
  }

  if (loading) return <></>

  return (
    <StartButtonContainer>
      <StartButton
        variant='contained'
        disableRipple
        disabled={!hasAccessToViewAssessment}
        onClick={handleClick}
      >
        Start Assessment
      </StartButton>
    </StartButtonContainer>
  )
}

export default AssessmentStartButton
