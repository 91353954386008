import React, { useState, useContext } from 'react'
import { Button, Box, ButtonProps, BoxProps, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Color, Dialog } from 'components'
import { AssessmentContext } from 'context/Assessment'
import { AccessContext } from 'context/AccessContext'

interface SubmitButtonBoxProps extends BoxProps {
  assessmentCompleted?: boolean
}

const SubmitButtonBox = styled(Box, {
  shouldForwardProp: prop => prop !== 'assessmentCompleted'
})<SubmitButtonBoxProps>(({ assessmentCompleted }) => ({
  backgroundColor: assessmentCompleted ? Color.blue50 : Color.grey50,
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 24,
  borderRadius: 8,
  height: '100%'
}))

const SubmitButton = styled(Button)<ButtonProps>(() => ({
  height: 60,
  width: 186,
  whiteSpace: 'nowrap',
  '&:disabled': {
    backgroundColor: '#E5E5E5',
    color: 'white'
  }
}))

interface iProps {
  employerAssessmentId?: string
}

const AssessmentSubmitButton = ({ employerAssessmentId }: iProps) => {
  const [open, setOpen] = useState(false)
  const { assessmentCompleted, onSubmitAssessment } =
    useContext(AssessmentContext)
  const { hasAccessToViewAssessment } = useContext(AccessContext)

  return (
    <SubmitButtonBox assessmentCompleted={assessmentCompleted}>
      <SubmitButton
        variant='contained'
        disableRipple
        disabled={!assessmentCompleted || !hasAccessToViewAssessment}
        onClick={() => setOpen(true)}
      >
        Submit Assessment
      </SubmitButton>
      <Dialog
        variant='secondary'
        open={open}
        title={'Submit your assessment'}
        cancelProps={{
          text: 'Cancel and go back',
          onClick: () => setOpen(false)
        }}
        confirmProps={{
          text: 'Confirm and submit',
          onClick: async () => {
            await onSubmitAssessment(
              {
                variables: {
                  id: employerAssessmentId
                }
              },
              '/equity-index'
            )
          }
        }}
        banner={{
          content: (
            <Typography variant='body2'>
              When you submit your answers, you&lsquo;ll see a Progress Score on
              your Dashboard, and customized Goals. You can always retake the
              Assessment (we recommend doing it quarterly), but remember: you
              can only get one score per quarter. So make sure you&lsquo;re
              ready!
            </Typography>
          ),
          multiline: true
        }}
        onClose={() => setOpen(false)}
      />
    </SubmitButtonBox>
  )
}

export default AssessmentSubmitButton
