import React from 'react'
import { IconButton, Typography, Grid } from '@mui/material'
import { ChevronRightIcon } from 'assets/icons'
import { Color } from 'components'

interface Props {
  content: string
}
const KCItem = ({ content }: Props) => {
  return (
    <Grid container marginTop='24px'>
      <Grid item xs={8} lineHeight='19px' sx={{ position: 'relative' }}>
        <Typography
          variant='body2Medium'
          sx={{
            margin: 0,
            position: 'absolute',
            top: '50%',
            msTransform: 'translate(0, -50%)',
            transform: 'translate(0, -50%)'
          }}
        >
          {content}
        </Typography>
      </Grid>
      <Grid xs item></Grid>
      <Grid item xs={3}>
        <IconButton
          sx={{ height: 40, width: 40, backgroundColor: Color.grey100 }}
        >
          <ChevronRightIcon size={40} />
        </IconButton>
      </Grid>
    </Grid>
  )
}
export default KCItem
