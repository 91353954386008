import { gql } from '@apollo/client'

export const CHECK_EMPLOYER_ASSESSMENT_STATUS = gql`
  query {
    employerAssessment {
      id
    }
  }
`

export const GET_EMPLOYER_ASSESSMENT_HISTORY = gql`
  query {
    employerAssessmentHistory {
      firstQuarter {
        id
        acquiredScore
        employerAssessmentCategories {
          id
          slug
          acquiredScore
        }
      }
      secondQuarter {
        id
        acquiredScore
        status
        employerAssessmentCategories {
          id
          name
          slug
          acquiredScore
        }
      }

      thirdQuarter {
        id
        acquiredScore
        employerAssessmentCategories {
          id
          name
          slug
          acquiredScore
        }
      }

      fourthQuarter {
        id
        acquiredScore
        employerAssessmentCategories {
          id
          name
          slug
          acquiredScore
        }
      }
    }
  }
`

// Mutations

// TODO: Will need to ask what clientMutationId is
export const CREATE_EMPLOYER_ASSESSMENT = gql`
  mutation {
    createEmployerAssessment(input: {}) {
      id
      employerId
      versionName
      status
      startAt
      createdAt
      updatedAt
    }
  }
`
