/* TODO: These models are created based on the mock server, modify these when we have the real API. */

// Data model for Dashboard and Assessment pages

export type CarouselSlide = {
  title: string
  content: string
  image?: string
  linkUrl?: string
}

export type CategoryScoreType = {
  name: string
  score: number
}

export type AllAssessments = {
  allAssessments: Assessment[] | EmployerAssessment[]
}
export type AssessmentData = {
  assessment: Assessment
}
export type Assessment = {
  id: string
  versionName?: string
  acquiredScore?: number
  totalScore?: number
  activeStatus?: string
  updatedAt?: string
  createdAt?: string
  categories?: Category[]
}

export type EmployerAssessmentData = {
  employerAssessment: EmployerAssessment
}

export type EmployerAssessmentHistory = {
  firstQuarter: EmployerQuarterHistory | null
  secondQuarter: EmployerQuarterHistory | null
  thirdQuarter: EmployerQuarterHistory | null
  fourthQuarter: EmployerQuarterHistory | null
}

export type EmployerQuarterHistory = {
  id: string
  acquiredScore: number
  employerAssessmentCategories: Category[]
}

export type EmployerAssessment = {
  id: string
  employerId?: string
  versionName?: string
  totalScore: number
  acquiredScore: number
  progress: number
  status: string
  createdAt?: string
  updatedAt: string
  employerAssessmentCategories: Category[]
}

export type Category = {
  id: string
  categoryId?: string
  name: string
  description?: string
  totalScore?: number
  acquiredScore?: number
  progress?: number
  slug: string
  Questions?: Question[]
  isCompleted?: boolean
  createdAt?: string
  updatedAt?: string
  __typename?: string
}

export type AllQuestions = {
  allQuestions: Question[]
}
export type Question = {
  id: string
  questionText: string
  description: string
  questionType: QuestionType | string
  order?: number | null
  subOrder?: number | null
  isCompleted?: boolean
  selectedAnswerIds?: string[]
  assessmentAnswers: Answer[]
  createdAt?: string
  updatedAt?: string
  __typename?: string
}

export type Answer = {
  id: string
  answerText: string
  order: string
  hasFollowupQuestion: boolean
  followupQuestionIds: string[] | null
  hasWeight?: boolean
  createdAt?: string
  updatedAt?: string
}

export enum QuestionType {
  SINGLE_SELECT = 'single_select',
  MULTI_SELECT = 'multi_select'
}

export enum CategoryStatus {
  STARTED,
  NOT_STARTED,
  COMPLETE
}

export type UpdateQuestionInput = {
  question: Question
  selectedAnswerIds: string[]
}

export type QuestionState = {
  questionsToSubmit: UpdateQuestionInput[]
  currentQuestionSelectedIds: string[]
  currentQuestionHasFollowup: boolean
}
