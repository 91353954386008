import React, { useEffect, useState } from 'react'
import { Button, ButtonProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

const DownloadButton = styled(Button)<ButtonProps>(() => ({
  backgroundColor: '#F2F3F3',
  textDecoration: 'none',
  padding: '12px 16px',
  borderRadius: '8px',
  fontSize: '16px',
  lineHeight: '24px',
  fontFamily: 'Roboto',
  '&:hover': {
    backgroundColor: '#F2F3F3',
    textDecoration: 'none'
  }
}))

interface IProps {
  isVisible: boolean
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
  downloadClicked: boolean
  setDownloadClicked: React.Dispatch<React.SetStateAction<boolean>>
  graphComplete: boolean
}

const DownloadDashboardButton = ({
  isVisible,
  setIsVisible,
  downloadClicked,
  setDownloadClicked,
  graphComplete
}: IProps) => {
  const isClicked: Function = () => {
    setIsVisible(true)
    setDownloadClicked(true)
  }

  const [downloaded, setDownloaded] = useState(false)

  useEffect(() => {
    if (isVisible && downloadClicked && graphComplete && !downloaded) {
      const date = new Date().toDateString().slice(4).split(' ').join('_')
      const scoreDashboard: any = document.getElementById('score-dashboard')

      html2canvas(scoreDashboard)
        .then(canvas => {
          const img = canvas.toDataURL('image/png')
          const pdf = new jsPDF('p', 'px')
          pdf.internal.pageSize.width = scoreDashboard.offsetWidth
          pdf.internal.pageSize.height = scoreDashboard.offsetHeight

          pdf.addImage(
            img,
            'PNG',
            10,
            10,
            scoreDashboard.offsetWidth - 40,
            scoreDashboard.offsetHeight - 40
          )
          pdf.save(`EHI_Dashboard_${date}.pdf`)
          setDownloaded(true)
          // Clear the downloadClicked event to prevent multiple downloads
          setDownloadClicked(false)
        })
        .then(() => {
          // After PDF is downloaded, reset downloaded event
          setDownloaded(false)
        })
    }
  }, [
    isVisible,
    downloadClicked,
    setDownloadClicked,
    graphComplete,
    downloaded,
    setDownloaded
  ])

  return (
    <DownloadButton disableRipple onClick={() => isClicked(true)}>
      Download as PDF
    </DownloadButton>
  )
}

export default DownloadDashboardButton
