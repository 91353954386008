import React from 'react'
import { Box, BoxProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { InfoBanner } from 'components/SharedComponents'

const BannerContainer = styled(Box)<BoxProps>(() => ({
  width: '100%',
  marginBottom: '24px'
}))

interface Props {
  setCloseFollowUpBanner: React.Dispatch<React.SetStateAction<boolean>>
}

const FollowUpBanner = ({ setCloseFollowUpBanner }: Props) => {
  return (
    <BannerContainer>
      <InfoBanner
        content={'Some questions have follow-ups.'}
        type='info'
        onClose={() => setCloseFollowUpBanner(true)}
      />
    </BannerContainer>
  )
}

export default FollowUpBanner
