import React, { useContext } from 'react'
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Drawer as MuiDrawer,
  DrawerProps
} from '@mui/material'
import { getNavigations } from './constants'
import { Navigation } from 'model'
import { HashLink } from 'react-router-hash-link'
import { useLocation } from 'react-router-dom'
import { AccessContext } from 'context/AccessContext'
import { styled } from '@mui/material/styles'

const drawerWidth = 196

const Drawer = styled(MuiDrawer)<DrawerProps>(() => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    paddingTop: '40px',
    backgroundColor: 'transparent',
    position: 'sticky',
    overflowY: 'inherit',
    minHeight: 'calc(100vh - 56px - 147px)',
    '& .MuiListItem-root': {
      padding: '8px 32px'
    },
    '& .MuiListItemText-root': {
      margin: 0,
      '& .active': {
        fontWeight: 700
      }
    }
  }
}))
const SidebarNavigation = () => {
  const { pathname } = useLocation()
  const { hasAccessToGoalManager, hasAccessToViewAssessment } =
    useContext(AccessContext)

  return (
    <Drawer variant='permanent'>
      <List disablePadding sx={{ position: 'sticky', top: '0px' }}>
        {getNavigations(hasAccessToGoalManager, hasAccessToViewAssessment).map(
          (navigation: Navigation, index: number) => (
            <ListItem key={index}>
              <HashLink to={navigation.link}>
                <ListItemText
                  primary={
                    <Typography
                      fontWeight={400}
                      fontSize={'16px'}
                      color={'black'}
                      lineHeight={'22px'}
                      letterSpacing={'-0.01em'}
                      className={pathname === navigation.link ? 'active' : ''}
                    >
                      {navigation.label}
                    </Typography>
                  }
                />
              </HashLink>
            </ListItem>
          )
        )}
      </List>
    </Drawer>
  )
}

export default SidebarNavigation
