import React, { createContext, useContext } from 'react'
import { useQuery } from '@apollo/client'
import { GET_EMPLOYER_ASSESSMENT_HISTORY } from './gql'
import { MicroServiceContext } from 'context/MicroService'
import { Goal } from 'model/goalManager'
enum EHIAccess {
  FULL_EHI_ACCESS = 'FULL_EHI_ACCESS',
  BASIC_EHI_ACCESS = 'BASIC_EHI_ACCESS',
  NO_EHI_ACCESS = 'NO_EHI_ACCESS'
}

export type Access = {
  hasAccessToGoalManager: boolean
  hasAccessToEditGoal: (goal: Goal) => boolean
  hasAccessToViewDashboardAndAssessment: boolean
  hasAccessToViewDataViz: boolean
  hasAccessToViewAssessment: boolean
  hasAccessToViewDashboard: boolean
  hasAccessToViewGoalManager: boolean
}

export const AccessContext = createContext<Access>({
  hasAccessToGoalManager: false,
  hasAccessToEditGoal: () => false,
  hasAccessToViewDashboardAndAssessment: false,
  hasAccessToViewDataViz: false,
  hasAccessToViewAssessment: false,
  hasAccessToViewDashboard: false,
  hasAccessToViewGoalManager: false
})

export const AccessProvider = ({ children }: any): JSX.Element => {
  const {
    currentAccount: { ehiAccess, id, maestroClient }
  } = useContext(MicroServiceContext)
  const hasAccessToEditGoal = (goal: Goal) =>
    ehiAccess === EHIAccess.FULL_EHI_ACCESS || goal.owner?.id === id
  const INITIAL_EMPLOYER_ASSESSMENT_HISTORY = {
    firstQuarter: null,
    secondQuarter: null,
    thirdQuarter: null,
    fourthQuarter: null
  }
  const {
    data: assessmentData = INITIAL_EMPLOYER_ASSESSMENT_HISTORY,
    loading
  } = useQuery(GET_EMPLOYER_ASSESSMENT_HISTORY, {
    client: maestroClient
  })
  if (loading) return <></>

  const isFirstAssessmentCompleted = Boolean(
    assessmentData.employerAssessmentHistory?.firstQuarter ||
      assessmentData.employerAssessmentHistory?.secondQuarter ||
      assessmentData.employerAssessmentHistory?.thirdQuarter ||
      assessmentData.employerAssessmentHistory?.fourthQuarter
  )

  return (
    <AccessContext.Provider
      value={{
        hasAccessToGoalManager: isFirstAssessmentCompleted,
        hasAccessToEditGoal: hasAccessToEditGoal,
        hasAccessToViewDashboardAndAssessment:
          ehiAccess === EHIAccess.FULL_EHI_ACCESS,
        hasAccessToViewDataViz: ehiAccess === EHIAccess.FULL_EHI_ACCESS,
        hasAccessToViewAssessment: ehiAccess === EHIAccess.FULL_EHI_ACCESS,
        hasAccessToViewDashboard: ehiAccess === EHIAccess.FULL_EHI_ACCESS,
        hasAccessToViewGoalManager: ehiAccess !== EHIAccess.NO_EHI_ACCESS
      }}
    >
      {children}
    </AccessContext.Provider>
  )
}
