import React, { useState, useContext, memo, useMemo } from 'react'
import { Grid, Typography, Button, Box, Stack } from '@mui/material'
import { GoalManagerContext } from 'context/GoalManager'
import { FormControl, TitleInput } from './styledComponents'
import { Divider, DescriptionBanner, InputErrorMessage } from 'components'
import { goalTitleCache } from 'utils/goalManager'
import { LoadButton } from '../../styledComponents'


interface Props {
  id: string
  rawTitle: string
  customTitle: string | null
  description: string
  readonly?: boolean
}

const GoalTitle = ({
  id,
  rawTitle,
  customTitle,
  description,
  readonly
}: Props) => {
  const [title, setTitle] = useState(
    goalTitleCache.getItem(id) || customTitle || rawTitle || ''
  )
  const [isEditingGoal, setIsEditingGoal] = useState(false)
  const [isLess5, setIsLess5] = useState(false)
  const [hasUnsavedChange, setHasUnsavedChange] = useState(false)
  const { submitEmployerGoal } = useContext(GoalManagerContext)
  const unsavedTitle = goalTitleCache.getItem(id)
  const [loading, setLoading] = useState(false)

  useMemo(() => {
    setIsLess5(false)
    setTitle(unsavedTitle || customTitle || rawTitle || '')
    if (!unsavedTitle) {
      setHasUnsavedChange(false)
      setIsEditingGoal(false)
    } else {
      setIsEditingGoal(true)
      setHasUnsavedChange(true)
    }
    //eslint-disable-next-line
  }, [id])

  const handleEndingChange = () => {
    setIsEditingGoal(false)
    setHasUnsavedChange(false)
    setIsLess5(false)
    goalTitleCache.removeItem(id)
    setLoading(false)
  }

  const clickSave = async () => {
    setHasUnsavedChange(false)
    if (title.length < 5) {
      setIsLess5(true)
    } else {
      await submitEmployerGoal(
        id,
        { customTitle: title },
        'Goal title'
      )
      handleEndingChange()
    }    
    setLoading(false)
  }

  return (
    <Grid
      container
      tabIndex={0}
      id='titleEditorContainer'
      onBlur={(e: any) => {
        if (
          document
            .getElementById('titleEditorContainer')!
            .contains(e.relatedTarget)
        )
          return

        if (title && title !== customTitle) {
          goalTitleCache.setItem(title, id)
          setIsLess5(false)
          setHasUnsavedChange(true)
        }
      }}
    >
      <Typography variant='button' letterSpacing={'.14em'}>
        GOAL
      </Typography>
      <Divider />
      {readonly ? (
        <Typography variant='h4' marginTop={3}>
          {customTitle || rawTitle}
        </Typography>
      ) : (
        <div style={{ marginTop: 16, width: '100%' }}>
          {!isEditingGoal && (
            <Box>
              <Typography variant='h3'>{customTitle || rawTitle}</Typography>
              <FormControl>
                <Button variant='action' onClick={() => setIsEditingGoal(true)}>
                  Edit goal
                </Button>
              </FormControl>
            </Box>
          )}
          {isEditingGoal && (
            <Box>
              <TitleInput
                fullWidth
                onFocus={e => {
                  e.stopPropagation()
                  setHasUnsavedChange(false)
                }}
                multiline
                inputProps={{ maxLength: 301 }}
                error={
                  !title || title.length >= 301 || isLess5 || hasUnsavedChange
                }
                value={title}
                onChange={e => {
                  setIsLess5(false)
                  e.stopPropagation()
                  setTitle(e.target.value)
                }}
              />
              {!title && (
                <InputErrorMessage>Goal must have a title</InputErrorMessage>
              )}
              {title && title.length > 300 && (
                <InputErrorMessage>
                  Goal title must be less than 300 characters
                </InputErrorMessage>
              )}
              {isLess5 && (
                <InputErrorMessage>
                  Goal title must be at least 5 characters
                </InputErrorMessage>
              )}
              {hasUnsavedChange && (
                <InputErrorMessage>You have unsaved changes</InputErrorMessage>
              )}
              <Stack
                direction='row'
                justifyContent='space-between'
                marginTop={2}
              >
                <Button
                  variant='underline'
                  onClick={e => {
                    e.preventDefault()
                    setTitle(rawTitle)
                    submitEmployerGoal(id, { customTitle: '' }, 'Goal title')
                    handleEndingChange()
                  }}
                >
                  Revert to original goal
                </Button>
                <Stack direction='row' spacing={1}>
                  <Button
                    variant='underline'
                    aria-label='goal-title-button'
                    onClick={e => {
                      e.preventDefault()
                      setTitle(customTitle || rawTitle)
                      handleEndingChange()
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadButton                     
                    variant='contained'
                    aria-label='goal-title-button'
                    size='small'
                    disabled={!title || title.length >= 301}
                    loading={loading}
                    loadingPosition="end"
                    onClick={() => {
                      setLoading(true)
                        clickSave()
                    }}
                  >
                    Save
                  </LoadButton>
                </Stack>
              </Stack>
            </Box>
          )}
          {description && <DescriptionBanner>{description}</DescriptionBanner>}
        </div>
      )}
    </Grid>
  )
}
export default memo(GoalTitle)
