import React, { useContext, useMemo, useState } from 'react'
import {
  Grid,
  Typography,
  Stack,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  IconButton,
  Button
} from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { GoalManagerContext } from 'context/GoalManager'
import { Divider, UserAvatar, Color, DatePicker } from 'components'
import AdapterMoment from '@mui/lab/AdapterMoment'
import { MicroServiceContext } from 'context/MicroService'
import { LocalizationProvider } from '@mui/lab'
import moment, { Moment } from 'moment'
import { ButtonSelect } from './styledComponents'
import { Member } from 'model/goalManager'
import MenuItemNoMembers from './MenuItemNoMembers'
import SelectedMembers from './SelectedMembers'
interface Props {
  readonly?: boolean
}

const GoalDetail = ({ readonly }: Props) => {
  const { displayNotification, currentAccount } =
    useContext(MicroServiceContext)
  const { selectedGoal, submitEmployerGoal, membersWithAccess } =
    useContext(GoalManagerContext)
  const { id, startDate: rawStartDate, endDate: rawEndDate } = selectedGoal!
  const [isShowingMore, setIsShowingMore] = useState(false)
  const [startDate, setStartDate] = useState<Moment | null>(null)
  const [endDate, setEndDate] = useState<Moment | null>(null)
  const teamMembers = selectedGoal!.members

  useMemo(() => {
    if (rawStartDate) {
      setStartDate(moment(rawStartDate))
    } else {
      setStartDate(null)
    }
    if (rawEndDate) {
      setEndDate(moment(rawEndDate))
    } else {
      setEndDate(null)
    }
    //eslint-disable-next-line
  }, [selectedGoal!.id])

  const submitStartDate = (dateInput: Moment) => {
    if (dateInput.isAfter(rawEndDate)) {
      displayNotification('End date can not be earlier than start date')
      return
    }
    submitEmployerGoal(
      id,
      {
        startDate: dateInput.toISOString()
      },
      'Start date'
    )
  }
  const submitEndDate = (dateInput: Moment) => {
    if (moment(rawStartDate).isAfter(dateInput)) {
      displayNotification('End date can not be earlier than start date')
      return
    }
    submitEmployerGoal(
      id,
      {
        endDate: dateInput.toISOString()
      },
      'End date'
    )
  }

  const availableTeamMembers = membersWithAccess.filter(
    (member: any) =>
      selectedGoal?.owner?.id !== member.node.id &&
      !teamMembers.find(m => m.id === member.node.id)
  )

  return (
    <Grid item xs={6.8}>
      <Typography variant='formLabel' letterSpacing={'.14em'}>
        GOAL DETAILS
      </Typography>
      <Divider />
      {readonly && selectedGoal?.owner?.id !== currentAccount.id ? (
        <Grid container rowSpacing={3} marginTop='-8px'>
          <Grid item xs={12}>
            <Typography
              variant='body14Light'
              color={Color.grey700}
              component='p'
            >
              Goal owner
            </Typography>
            <Typography variant='body16'>
              {selectedGoal?.owner?.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body14Light' color={Color.grey700}>
              Team members
            </Typography>
            {teamMembers.length > 0 &&
              teamMembers.map((member: any) => (
                <Stack
                  direction='row'
                  key={`member-${member.name}`}
                  padding='12px 0'
                  alignItems='center'
                >
                  <UserAvatar
                    username={member.name ?? member.primaryEmail}
                    size={32}
                  ></UserAvatar>
                  <Stack marginLeft={1}>
                    <Typography variant='body14'>{member.name}</Typography>
                    <Typography variant='body14Light'>
                      {member.primaryEmail}
                    </Typography>
                  </Stack>
                </Stack>
              ))}
          </Grid>
          <Grid item xs={4.8}>
            <Typography
              variant='body14Light'
              color={Color.grey700}
              component='p'
            >
              Start date
            </Typography>
            <Typography variant='body16'>
              {moment(rawStartDate).format('MMMM YYYY')}
            </Typography>
          </Grid>
          <Grid item xs={7.2}>
            <Typography
              variant='body14Light'
              color={Color.grey700}
              component='p'
            >
              End date
            </Typography>
            <Typography variant='body16'>
              {moment(rawEndDate).format('MMMM YYYY')}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <>
          <FormControl fullWidth variant='filled'>
            <InputLabel>Owned by</InputLabel>
            <Select
              value={selectedGoal?.owner}
              label='Owned by'
              renderValue={(member: any) => {
                return (
                  <Typography variant='body16'>
                    {member.name ?? member.primaryEmail}
                  </Typography>
                )
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 66,
                  horizontal: 'left'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                },
                PaperProps: { sx: { maxHeight: 270 } }
              }}
              onChange={e => {
                const {
                  id: ownerId,
                  name,
                  primaryEmail
                }: Member = e.target.value as Member
                const payload = {
                  id: ownerId,
                  name: name,
                  primaryEmail: primaryEmail
                }
                submitEmployerGoal(id, { owner: payload }, 'Goal Owner')
              }}
              endAdornment={
                selectedGoal?.owner?.name ? (
                  <IconButton
                    edge='end'
                    onClick={() => {
                      submitEmployerGoal(
                        id,
                        { owner: {} as Member },
                        'Goal Owner Removal'
                      )
                    }}
                    sx={{ position: 'absolute', right: '44px' }}
                  >
                    <CloseIcon />
                  </IconButton>
                ) : undefined
              }
            >
              {availableTeamMembers.length > 0 ? (
                availableTeamMembers.map((member: any) => {
                  const {
                    node: {
                      id,
                      user: { name, primaryEmail }
                    }
                  } = member
                  return (
                    <MenuItem
                      value={{ id, name, primaryEmail } as any}
                      key={id}
                    >
                      <Grid container spacing={2}>
                        <Grid item>
                          <UserAvatar
                            size={32}
                            username={name ?? primaryEmail}
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            variant='body2'
                            component={'div'}
                            fontWeight={600}
                          >
                            {name}
                          </Typography>
                          <Typography variant='body2'>
                            {primaryEmail}
                          </Typography>
                        </Grid>
                      </Grid>
                    </MenuItem>
                  )
                })
              ) : (
                <MenuItemNoMembers />
              )}
            </Select>
          </FormControl>
          <ButtonSelect fullWidth>
            <Select
              renderValue={() => (
                <Typography variant='body16'>Add team members</Typography>
              )}
              value={{}}
              MenuProps={{
                anchorOrigin: {
                  vertical: 66,
                  horizontal: 'left'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                },
                PaperProps: { sx: { maxHeight: 270 } }
              }}
              onChange={e => {
                let thisTeamMembers = [...teamMembers]
                thisTeamMembers.push(e.target.value as Member)
                thisTeamMembers = thisTeamMembers.map((m: any) => {
                  const payload = {
                    id: m.id,
                    name: m.name,
                    primaryEmail: m.primaryEmail
                  }
                  return payload
                })
                submitEmployerGoal(
                  id,
                  { members: thisTeamMembers },
                  'Team Members'
                )
              }}
            >
              {availableTeamMembers.length > 0 ? (
                availableTeamMembers.map((member: any) => {
                  const {
                    node: {
                      id,
                      user: { name, primaryEmail }
                    }
                  } = member
                  return (
                    <MenuItem
                      value={{ id, name, primaryEmail } as any}
                      key={id}
                    >
                      <Grid container spacing={2}>
                        <Grid item>
                          <UserAvatar
                            size={32}
                            username={name ?? primaryEmail}
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            variant='body2'
                            component={'div'}
                            fontWeight={600}
                          >
                            {name}
                          </Typography>
                          <Typography variant='body2'>
                            {primaryEmail}
                          </Typography>
                        </Grid>
                      </Grid>
                    </MenuItem>
                  )
                })
              ) : (
                <MenuItemNoMembers />
              )}
            </Select>
          </ButtonSelect>
          <SelectedMembers isShowingMore={isShowingMore} />
          {teamMembers.length > 5 && (
            <Button
              variant='underline'
              sx={{ marginLeft: 'auto', display: 'block', marginTop: '8px' }}
              onClick={() => {
                setIsShowingMore(!isShowingMore)
              }}
            >
              View {isShowingMore ? 'less' : 'more'}
            </Button>
          )}

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Stack direction='row' spacing={2} alignItems='baseline'>
              <DatePicker
                label='Start date'
                value={startDate}
                defaultValue={selectedGoal?.startDate}
                onAccept={(date: Moment) => submitStartDate(date)}
                onChange={(newValue: Moment | null) => {
                  setStartDate(newValue)
                }}
              />
              <DatePicker
                label='End date'
                isEndMonth
                value={endDate}
                defaultValue={selectedGoal?.endDate}
                onAccept={(date: Moment) => submitEndDate(date)}
                onChange={(newValue: Moment | null) => {
                  setEndDate(newValue)
                }}
              />
            </Stack>
          </LocalizationProvider>
        </>
      )}
    </Grid>
  )
}
export default GoalDetail
