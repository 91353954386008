import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Grid,
  GridProps,
  Button,
  IconButton,
  Box,
  ButtonProps
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { Color, ArrowLeftIcon, ArrowRightIcon } from 'components'
import { AssessmentContext } from 'context/Assessment'
import { QuestionState, Question } from 'model/assessment'

const SpaceBetweenContainer = styled(Grid)<GridProps>(() => ({
  display: 'flex',
  justifyContent: 'space-between'
}))

const DoneButton = styled(Button)<ButtonProps>(() => ({
  color: Color.white,
  backgroundColor: Color.black,
  marginLeft: '16px',
  '&:hover': {
    color: Color.white,
    backgroundColor: Color.black
  }
}))

const ExitButton = styled(Button)<ButtonProps>(() => ({
  minWidth: '24px',
  padding: 0,
  textDecoration: 'underline',
  '&:hover': {
    textDecoration: 'underline',
    backgroundColor: 'transparent'
  }
}))

interface IProps {
  categorySlug: string
  currentOrder?: string
  totalNumberOfQuestions: number
  questionId: string
  question: Question
  prevSelectedAnswerIds: string[]
  questionState: QuestionState
  setQuestionState: React.Dispatch<any>
  setIsFollowup: React.Dispatch<boolean>
  isFollowup: boolean
  followupId: string[]
  setFollowupId: React.Dispatch<any>
  setAnswerSubmitted: React.Dispatch<React.SetStateAction<boolean>>
}

const QuestionNavigation = ({
  categorySlug,
  currentOrder,
  totalNumberOfQuestions,
  questionId,
  question,
  prevSelectedAnswerIds,
  questionState,
  setQuestionState,
  setIsFollowup,
  isFollowup,
  followupId,
  setFollowupId,
  setAnswerSubmitted
}: IProps) => {
  const navigate = useNavigate()
  const { onUpdateQuestion, employerAssessmentData } =
    useContext(AssessmentContext)
  const currentSelection = questionState.currentQuestionSelectedIds

  const shouldUpdateQuestion = (
    prevSelection: string[],
    newSelection: string[]
  ) => {
    const newSelectionArray =
      typeof newSelection === 'string' ? [newSelection] : newSelection
    if (
      prevSelection &&
      prevSelection.length === 0 &&
      newSelection &&
      newSelectionArray.length === 0
    )
      return false
    if (
      prevSelection &&
      newSelection &&
      prevSelection.length !== newSelectionArray.length
    )
      return true
    const prevValues =
      prevSelection && prevSelection.length > 0
        ? [...prevSelection].sort()
        : undefined
    const newValues =
      newSelectionArray && newSelectionArray.length > 0
        ? [...newSelectionArray].sort()
        : undefined
    return JSON.stringify(prevValues) !== JSON.stringify(newValues)
  }

  const updateQuestion = async (isFollowup: boolean, direction: string) => {
    const navigateTo =
      direction === 'exit'
        ? '/equity-index/assessment'
        : direction === 'back'
        ? `/equity-index/assessment/${categorySlug}/${Number(currentOrder) - 1}`
        : `/equity-index/assessment/${categorySlug}/${Number(currentOrder) + 1}`
    if (
      questionState.questionsToSubmit.length !== followupId.length &&
      direction === 'forward'
    ) {
      setQuestionState({
        type: 'saveCurrentSelection',
        value: {
          question: question,
          selectedAnswerIds: currentSelection
        }
      })
      setIsFollowup(true)
      return
    } else if (
      questionState.currentQuestionHasFollowup &&
      direction === 'forward'
    ) {
      setQuestionState({
        type: 'saveCurrentSelection',
        value: {
          question: question,
          selectedAnswerIds: currentSelection
        }
      })
      setIsFollowup(true)
      return
    }
    if (shouldUpdateQuestion(prevSelectedAnswerIds, currentSelection)) {
      const restructuredFollowupQuestions = [
        ...questionState.questionsToSubmit.slice(1)
      ].map(question => {
        return {
          id: question.question.id,
          selectedAnswerIds: question.selectedAnswerIds
        }
      })
      await onUpdateQuestion(
        {
          variables: {
            id: isFollowup
              ? questionState.questionsToSubmit[0].question.id
              : questionId,
            employerAssessmentId: employerAssessmentData?.id,
            selectedAnswerIds: isFollowup
              ? questionState.questionsToSubmit[0].selectedAnswerIds
              : typeof currentSelection === 'string'
              ? currentSelection
              : [...currentSelection],
            followupQuestions: isFollowup
              ? [
                  ...restructuredFollowupQuestions,
                  { id: question.id, selectedAnswerIds: currentSelection }
                ]
              : []
          }
        },
        navigateTo
      )
      setQuestionState({ type: 'resetQuestionState', value: '' })
      setIsFollowup(false)
      setFollowupId([])
      setAnswerSubmitted(true)
    } else {
      setQuestionState({ type: 'resetQuestionState', value: '' })
      setIsFollowup(false)
      setFollowupId([])
      navigate(navigateTo)
    }
  }

  const handleExit = () => {
    updateQuestion(isFollowup, 'exit')
  }

  const handleNavigateBack = () => {
    updateQuestion(isFollowup, 'back')
  }

  const handleNavigateForward = () => {
    updateQuestion(isFollowup, 'forward')
  }

  return (
    <SpaceBetweenContainer item xs={12}>
      {Number(currentOrder) !== 1 ? (
        <IconButton
          onClick={handleNavigateBack}
          disableRipple
          disabled={Number(currentOrder) === 1}
          sx={{
            padding: 0,
            height: '48px',
            width: '48px',
            backgroundColor: Color.grey200,
            ':disabled': {
              '& >svg': {
                color: Color.grey200
              }
            }
          }}
        >
          <ArrowLeftIcon color='secondary' />
        </IconButton>
      ) : (
        <div></div>
      )}
      <Box>
        <ExitButton variant='text' disableRipple onClick={handleExit}>
          Exit
        </ExitButton>
        {Number(currentOrder) !== totalNumberOfQuestions ? (
          <IconButton
            onClick={handleNavigateForward}
            disableRipple
            sx={{
              marginLeft: '16px',
              padding: 0,
              height: '48px',
              width: '48px',
              backgroundColor: Color.black
            }}
          >
            <ArrowRightIcon color='primary' />
          </IconButton>
        ) : (
          <DoneButton disableRipple onClick={handleExit}>
            Done
          </DoneButton>
        )}
      </Box>
    </SpaceBetweenContainer>
  )
}

export default QuestionNavigation
