import React, { useContext, useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Grid } from '@mui/material'
import { SidebarNavigation } from 'components'
import Dashboard from 'pages/Dashboard'
import Assessment from 'pages/Assessment'
import GoalManager from 'pages/GoalManager'
import { AccessContext } from 'context/AccessContext'
import { DashboardProvider } from 'context/Dashboard'
import PageNotFound from 'pages/PageNotFound'
import { errorPathname, plainTemplatePaths } from './constants'

const RouteProvider = () => {
  const { hasAccessToViewDashboardAndAssessment, hasAccessToViewGoalManager } =
    useContext(AccessContext)
  const [hideSidebar, setHideSidebar] = useState(false)
  const currentPath = window.location.pathname

  useEffect(() => {
    setHideSidebar(!hasAccessToViewDashboardAndAssessment)
  }, [hasAccessToViewDashboardAndAssessment])

  return (
    <Grid
      sx={{
        maxWidth: 1460,
        margin: 'auto',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      {!plainTemplatePaths.has(currentPath) && !hideSidebar && (
        <SidebarNavigation />
      )}
      <Grid padding={6} sx={{ width: '100%' }}>
        <Routes>
          <Route
            path='/equity-index'
            element={
              hasAccessToViewDashboardAndAssessment ? (
                <DashboardProvider>
                  <Dashboard />
                </DashboardProvider>
              ) : (
                <Navigate to={'/equity-index/goal-manager'} />
              )
            }
          />
          <Route
            path='/equity-index/assessment/*'
            element={
              <DashboardProvider>
                <Assessment setHideSidebar={setHideSidebar} />
              </DashboardProvider>
            }
          />
          <Route
            path='/equity-index/goal-manager'
            element={
              hasAccessToViewGoalManager ? (
                <GoalManager />
              ) : (
                <Navigate to={errorPathname} />
              )
            }
          ></Route>
          <Route path={errorPathname} element={<PageNotFound />} />
        </Routes>
      </Grid>
    </Grid>
  )
}
export default RouteProvider
