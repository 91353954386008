import React, { useContext } from 'react'
import { Grid } from '@mui/material'
import { GoalManagerContext } from 'context/GoalManager'
import Notes from './Notes'
import { GoalCard } from 'components'
import KCLinks from './KCLinks'
import { AccessContext } from 'context/AccessContext'
import GoalTitle from './Title'
import GoalDetail from './Detail'
import HeaderActions from './HeaderActions'

const ExpandedGoal = () => {
  const { hasAccessToEditGoal } = useContext(AccessContext)
  const { selectedGoal } = useContext(GoalManagerContext)

  if (!selectedGoal)
    return (
      <GoalCard sx={{ padding: '32px', height: '100%' }} selected></GoalCard>
    )

  const { isCompleted, customTitle, title, id, note } = selectedGoal
  const isReadOnly = !hasAccessToEditGoal(selectedGoal) || isCompleted
  return (
    <GoalCard sx={{ padding: '32px' }} selected>
      {!isReadOnly && <HeaderActions />}
      <Grid container marginBottom='48px'>
        <GoalTitle
          id={id}
          customTitle={customTitle}
          rawTitle={title}
          description={selectedGoal.description}
          readonly={isReadOnly}
        />
        <Grid container spacing={2.5} marginTop='48px'>
          <GoalDetail readonly={isReadOnly} />
          <KCLinks />
        </Grid>
      </Grid>
      <Notes id={id} savedNote={note} readonly={isReadOnly} />
    </GoalCard>
  )
}
export default ExpandedGoal
