import parse from 'url-parse'
const lowerWordRegex =
  /\b(?:an?d?|a[st]|because|but|by|en|for|i[fn]|neither|nor|o[fnr]|only|over|per|so|some|tha[tn]|the|to|up|upon|vs?\.?|versus|via|when|with|without|yet)\b/i

const upperWords = ['dei']

const capitalize = (str: string, prepositionsTest?: boolean) =>
  lowerWordRegex.test(str) && prepositionsTest
    ? str
    : str.charAt(0).toUpperCase() + str.slice(1)

// Amazing function that will transfer URL to title case  by Bowen
export const parseUrl = (url: string) => {
  let result = ''
  const parsed = parse(url, true)
  const pathname = parsed.pathname
  const dirs = pathname.split('/').slice(1)
  if (dirs[0] === 'bias-scanner') {
    result = 'Mathison Bias Scanner'
  }
  if (dirs[0] === 'knowledge-center') {
    switch (dirs[1]) {
      case 'category':
        result = `${dirs[3]
          .split('-')
          .map((word: string) => capitalize(word))
          .join(' ')}: `
        result =
          result +
          dirs[4]
            .split('-')
            .map((word: string, index: number) =>
              upperWords.includes(word)
                ? word.toUpperCase()
                : capitalize(word, index !== 0)
            )
            .join(' ')

        break
      case 'hiring-for-diversity':
        result = `Hiring for Diversity ${capitalize(dirs[2])} ${dirs[3]}`
        break
    }
  }

  return result
}
