import moment, { Moment } from 'moment'
import { GoalNode, GoalCounter, ACTIVE_STATUS, GOAL_CATEGORY, PHASE_STATE } from 'model/goalManager'

export const toMomentDate = (date: string | Moment | null, end?: boolean) => {
  if (end === true) {
    return moment(date, ['MM/YYYY', 'MMMM YYYY']).endOf('month')
  } else {
    return moment(date, ['MM/YYYY', 'MMMM YYYY'])
  }
}

export const validateMomentDate = (date: string) => {
  const momentCurrentDate = moment()
  if (
    moment(date, 'MM/YYYY').isValid() &&
    momentCurrentDate.isSameOrBefore(moment(date, 'MM/YYYY'), 'month')
  ) {
    return true
  } else if (
    moment(date, 'MMMM YYYY').isValid() &&
    momentCurrentDate.isSameOrBefore(moment(date, 'MMMM YYYY'), 'month')
  ) {
    return true
  }
  return false
}
export const parseGoalCategory = (category: string) => {
  const letters = category?.split('_')
  return `${letters[0].charAt(0).toUpperCase()}&${letters[1]
    ?.charAt(0)
    .toUpperCase()}`
}

export const filterGoalsByCategory = (
  goalNodes: GoalNode[],
  category: string
) => {
  const result = goalNodes

  if (category === 'all') {
    return result
  }

  return result.filter((goal: GoalNode) => goal.node.category === category)
}

export const sortGoalsByPhaseState = (goals: GoalNode[], phase: string) => {
  if (phase === 'all') {
    return goals.filter((goal: GoalNode) => goal.node.isCompleted === false)
  } else if (phase === 'completed') {
    return goals.filter((goal: GoalNode) => goal.node.isCompleted === true)
  } else {
    return goals.filter(
      (goal: GoalNode) =>
        goal.node.phase === phase && goal.node.isCompleted === false
    )
  }
}

export const goalTitleCache = {
  getItem: (id?: string) => localStorage.getItem(`goal-title-${id}`),
  setItem: (value: string, id?: string) =>
    localStorage.setItem(`goal-title-${id}`, value),
  removeItem: (id?: string) => localStorage.removeItem(`goal-title-${id}`)
}

export const goalNoteCache = {
  getItem: (id?: string) => localStorage.getItem(`goal-note-${id}`),
  setItem: (value: string, id?: string) =>
    localStorage.setItem(`goal-note-${id}`, value),
  removeItem: (id?: string) => localStorage.removeItem(`goal-note-${id}`)
}

export const getGoalCount = (goals: GoalNode[]): GoalCounter => {
  const count = {
    all: { total: goals.length, active: 0, title: GOAL_CATEGORY.all },
    defining_tracking: { total: 0, active: 0, title: GOAL_CATEGORY.defining_tracking },
    attracting_sourcing: { total: 0, active: 0, title: GOAL_CATEGORY.attracting_sourcing },
    interviewing_engaging: { total: 0, active: 0, title: GOAL_CATEGORY.interviewing_engaging },
    onboarding_advancing: { total: 0, active: 0, title: GOAL_CATEGORY.onboarding_advancing }
  }
  goals.forEach(({node}) => {
    if (node.activeStatus === ACTIVE_STATUS.ACTIVE && node.phase !== PHASE_STATE.completed)
      count.all.active++
    if (node.category === 'defining_tracking') {
      count.defining_tracking.total++
      if (node.activeStatus === ACTIVE_STATUS.ACTIVE && node.phase !== PHASE_STATE.completed)
        count.defining_tracking.active++
    } else if (node.category === 'attracting_sourcing') {
      count.attracting_sourcing.total++
      if (node.activeStatus === ACTIVE_STATUS.ACTIVE && node.phase !== PHASE_STATE.completed)
        count.attracting_sourcing.active++
    } else if (node.category === 'interviewing_engaging') {
      count.interviewing_engaging.total++
      if (node.activeStatus === ACTIVE_STATUS.ACTIVE && node.phase !== PHASE_STATE.completed)
        count.interviewing_engaging.active++
    } else if (node.category === 'onboarding_advancing') {
      count.onboarding_advancing.total++
      if (node.activeStatus === ACTIVE_STATUS.ACTIVE && node.phase !== PHASE_STATE.completed)
        count.onboarding_advancing.active++
    }
  })
  return count
}
