import React, { useContext } from 'react'
import { Grid, Typography } from '@mui/material'
import { GoalManagerProvider } from 'context/GoalManager'
import TabPanel from './CategoryTabPanel'
import MiniDashboard from './MiniDashboard'
import { AccessContext } from 'context/AccessContext'
const GoalManager = () => {
  const { hasAccessToViewDataViz } = useContext(AccessContext)
  return (
    <GoalManagerProvider>
      <Grid container flexDirection='column' wrap='nowrap' minHeight={'100%'}>
        <Typography variant='h1' marginBottom={1}>
          Goal Manager
        </Typography>
        {hasAccessToViewDataViz && <MiniDashboard />}
        <TabPanel />
      </Grid>
    </GoalManagerProvider>
  )
}
export default GoalManager
