import React from 'react'
import { Grid, GridProps } from '@mui/material'
import QuestionContent from './QuestionContent'
import QuestionTermsDropdown from './QuestionTermsDropdown'
import { styled } from '@mui/material/styles'
import { Question, QuestionState } from 'model/assessment'

const ActiveQuestionContainer = styled(Grid)<GridProps>(() => ({
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'row'
}))

interface IProps {
  question: Question
  questionOrder: string
  questionState: QuestionState
  setQuestionState: React.Dispatch<any>
  isFollowup: boolean
  setFollowupId: React.Dispatch<any>
}

const ActiveQuestion = ({
  question,
  questionOrder,
  questionState,
  setQuestionState,
  isFollowup,
  setFollowupId
}: IProps) => {
  return (
    <ActiveQuestionContainer container>
      <Grid item xs={12} lg={7}>
        <QuestionContent
          question={question}
          questionOrder={questionOrder}
          questionState={questionState}
          setQuestionState={setQuestionState}
          isFollowup={isFollowup}
          setFollowupId={setFollowupId}
        />
      </Grid>
      <Grid item xs={12} lg={5}>
        {question.description && (
          <QuestionTermsDropdown description={question.description} />
        )}
      </Grid>
    </ActiveQuestionContainer>
  )
}

export default ActiveQuestion
