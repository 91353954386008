import { gql } from '@apollo/client'

export const GET_ASSESSMENT = gql`
  query GetAssessment {
    assessment {
      id
      versionName
      totalScore
      activeStatus
      createdAt
      updatedAt
      categories {
        id
        name
        slug
        description
        totalScore
        createdAt
        updatedAt
      }
    }
  }
`

export const GET_EMPLOYER_ASSESSMENT = gql`
  query {
    employerAssessment {
      id
      employerId
      versionName
      totalScore
      acquiredScore
      progress
      status
      createdAt
      updatedAt
      employerAssessmentCategories {
        id
        categoryId
        name
        slug
        description
        totalScore
        acquiredScore
        progress
        isCompleted
        createdAt
        updatedAt
      }
    }
  }
`

export const GET_CATEGORY = gql`
  query GetCategory($id: ID!) {
    Category(id: $id) {
      name
      slug
      Questions {
        id
        questionText
        description
        questionType
        order
        subOrder
        selectedAnswerId
        Answers {
          id
          answerText
          order
          hasFollowupQuestion
          followupQuestionIds
        }
      }
    }
  }
`

export const GET_QUESTIONS_BY_CATEGORY = gql`
  query ($employerAssessmentCategoryId: ID!, $employerAssessmentId: ID!) {
    employerAssessmentQuestions(
      employerAssessmentId: $employerAssessmentId
      employerAssessmentCategoryId: $employerAssessmentCategoryId
    ) {
      id
      questionText
      order
      isCompleted
    }
  }
`

export const GET_QUESTION_IDS_BY_CATEGORY = gql`
  query ($employerAssessmentCategoryId: ID!, $employerAssessmentId: ID!) {
    employerAssessmentQuestions(
      employerAssessmentId: $employerAssessmentId
      employerAssessmentCategoryId: $employerAssessmentCategoryId
    ) {
      id
      order
    }
  }
`

export const GET_QUESTION = gql`
  query ($assessmentQuestionId: ID!) {
    assessmentQuestion(assessmentQuestionId: $assessmentQuestionId) {
      id
      questionText
      description
      questionType
      order
      subOrder
      totalScore
      createdAt
      updatedAt
      assessmentAnswers {
        id
        answerText
        order
        hasFollowupQuestion
        followupQuestionIds
        hasWeight
      }
    }
  }
`

export const GET_EMPLOYER_QUESTION = gql`
  query ($id: ID!) {
    employerAssessmentQuestion(id: $id) {
      id
      questionText
      description
      questionType
      order
      totalScore
      acquiredScore
      isCompleted
      createdAt
      updatedAt
      assessmentAnswers {
        id
        answerText
        order
        hasFollowupQuestion
        followupQuestionIds
        hasWeight
      }
      selectedAnswerIds

      followupQuestions {
        assessmentQuestionId
        questionText
        description
        questionType
        subOrder
        totalScore
        acquiredScore
        isCompleted
        createdAt
        updatedAt
        selectedAnswerIds

        assessmentAnswers {
          id
          answerText
          order
          hasFollowupQuestion
          followupQuestionIds
          hasWeight
        }
      }
    }
  }
`

// Mutations

export const UPDATE_QUESTION = gql`
  mutation (
    $id: ID!
    $employerAssessmentId: ID!
    $selectedAnswerIds: [ID]
    $followupQuestions: [EmployerAssessmentFollowupQuestionInput!]
  ) {
    updateEmployerAssessmentQuestion(
      input: {
        id: $id
        employerAssessmentId: $employerAssessmentId
        selectedAnswerIds: $selectedAnswerIds
        followupQuestions: $followupQuestions
      }
    ) {
      id
      questionText
      description
      questionType
      order
      totalScore
      acquiredScore
      isCompleted
      createdAt
      updatedAt
      selectedAnswerIds

      assessmentAnswers {
        id
        answerText
        order
        hasFollowupQuestion
        followupQuestionIds
      }
      followupQuestions {
        assessmentQuestionId
        questionText
        description
        questionType
        subOrder
        totalScore
        acquiredScore
        isCompleted
        createdAt
        updatedAt
        selectedAnswerIds

        assessmentAnswers {
          id
          answerText
          order
          hasFollowupQuestion
          followupQuestionIds
        }
      }
    }
  }
`

export const SUBMIT_ASSESSMENT = gql`
  mutation ($id: ID!) {
    submitEmployerAssessment(input: { id: $id }) {
      id
      employerId
      versionName
      totalScore
      acquiredScore
      progress
      status
      startAt
      endAt
      createdAt
      updatedAt
    }
  }
`
