import React from 'react'
import { Paper, PaperProps, Typography, Box } from '@mui/material'
import { ArrowDropDownRounded } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import { Question } from 'model/assessment'
import CategoryQuestionListItem from './CategoryQuestionListItem'
import { Color } from 'components'

const QuestionListContainer = styled(Paper)<PaperProps>(() => ({
  width: '100%',
  padding: '24px 24px 8px 24px'
}))

interface IProps {
  questions: Question[]
  slug?: string
}

const CategoryQuestionList = ({ questions, slug }: IProps) => {
  return (
    <QuestionListContainer variant='outlined' sx={{ marginTop: '32px' }}>
      <Box sx={{ display: 'flex', marginBottom: '18px', width: '100%' }}>
        <Typography
          variant='tableBold'
          sx={{
            borderRight: `1px solid ${Color.border}`,
            paddingRight: '42px',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          Completed
          <ArrowDropDownRounded sx={{ fontSize: 32 }} />
        </Typography>
        <Typography
          variant='tableBold'
          sx={{
            marginLeft: '12px',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          Question
          <ArrowDropDownRounded sx={{ fontSize: 32 }} />
        </Typography>
      </Box>
      {questions.map((question: Question, index: number) => (
        <CategoryQuestionListItem
          slug={slug}
          key={`question_${index}`}
          question={question}
          order={question.order}
        />
      ))}
    </QuestionListContainer>
  )
}

export default CategoryQuestionList
