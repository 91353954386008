import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Paper,
  Typography,
  Grid,
  Button,
  Divider,
  DividerProps
} from '@mui/material'
import { Color } from 'components'
import { styled } from '@mui/material/styles'
import SwapVertRoundedIcon from '@mui/icons-material/SwapVertRounded'

interface IProps {
  noAssessmentsCompleted: boolean
}

const VerticalDivider = styled(Divider)<DividerProps>(() => ({
  height: '40px',
  margin: '0 12px'
}))
const GoalsTable = ({ noAssessmentsCompleted }: IProps) => {
  const navigate = useNavigate()
  return (
    <Paper
      variant='outlined'
      sx={{ padding: '40px 0 0 0', minHeight: '281px', minWidth: '540px' }}
    >
      <Grid container padding='0 32px'>
        <Grid item container lg={6.5} xs={4}>
          <Typography variant='h6' color={Color.grey400}>
            Goal
          </Typography>
          <SwapVertRoundedIcon color='disabled' />
        </Grid>
        <VerticalDivider orientation='vertical' />

        <Grid item container lg={2} xs={3}>
          <Typography variant='h6' color={Color.grey400}>
            Category
          </Typography>
          <SwapVertRoundedIcon color='disabled' />
        </Grid>
        <VerticalDivider orientation='vertical' />

        <Grid item container lg={2} xs={3} flexWrap='nowrap'>
          <Typography variant='h6' color={Color.grey400}>
            Completed date
          </Typography>
          <SwapVertRoundedIcon color='disabled' />
        </Grid>
      </Grid>
      {noAssessmentsCompleted === false && (
        <>
          <Grid container padding='0 32px'>
            <Typography variant='h5' margin='56px auto 87px auto'>
              This is where you’ll see your recently completed goals.
            </Typography>
          </Grid>
          <Divider />
          <Grid
            container
            padding='32px'
            justifyContent='space-between'
            alignItems='center'
          >
            <Typography variant='h5'>Want to see all your goals?</Typography>
            <Button
              variant='contained'
              onClick={() => navigate('/equity-index/goal-manager')}
            >
              Manage your goals
            </Button>
          </Grid>
        </>
      )}
    </Paper>
  )
}

export default GoalsTable
