import React, { useContext } from 'react'
import { Grid, Typography } from '@mui/material'
import { GoalManagerContext } from 'context/GoalManager'
import { Divider } from 'components'
import KCItem from './KCItem'
import { parseUrl } from 'utils/KCUrlPaser'
const KCLinks = () => {
  const { selectedGoal } = useContext(GoalManagerContext)

  return (
    <Grid item xs={5.2}>
      <Typography variant='formLabel' letterSpacing='.14em'>
        RESOURCES
      </Typography>
      {selectedGoal?.kcUrls.map((link: string, index: number) => {
        const content = parseUrl(link)
        return (
          <a
            href={link}
            target='_blank'
            key={index}
            rel='noreferrer'
            style={{
              color: 'unset',
              textDecoration: 'unset',
              position: 'relative',
              display: 'block',
              height: 80
            }}
          >
            <Divider />
            <KCItem content={content} />
          </a>
        )
      })}
      <Divider />
    </Grid>
  )
}
export default KCLinks
