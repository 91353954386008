import React from 'react'
import { Box, BoxProps } from '@mui/material'
import { Color } from 'components'
import { CATEGORY_BAR_BACKGROUND, CATEGORY_BAR_GRADIENT } from 'constants/'
import { Category } from 'model/assessment'
import { styled } from '@mui/material/styles'

interface ProgressBarProps extends BoxProps {
  categoryCompleted?: boolean
  hasActiveAssessment?: boolean
  category: Category
}

const ProgressBarBackground = styled(Box, {
  shouldForwardProp: prop =>
    prop !== 'categoryCompleted' &&
    prop !== 'hasActiveAssessment' &&
    prop !== 'category'
})<ProgressBarProps>(
  ({ categoryCompleted, hasActiveAssessment, category }) => ({
    border: categoryCompleted ? `1px solid ${Color.border}` : 'none',
    margin: 'auto',
    boxSizing: 'border-box',
    borderRadius: '100px',
    width: 261,
    height: 24,
    backgroundColor: hasActiveAssessment
      ? CATEGORY_BAR_BACKGROUND[category.name]
      : Color.grey50
  })
)

const ProgressBarForeground = styled(Box, {
  shouldForwardProp: prop =>
    prop !== 'hasActiveAssessment' && prop !== 'category'
})<ProgressBarProps>(({ hasActiveAssessment, category }) => ({
  borderRadius:
    category.progress && category.progress < 95 ? '100px 0 0 100px' : '100px',
  width: `${category.progress}%`,
  height: '100%',
  background: hasActiveAssessment
    ? CATEGORY_BAR_GRADIENT[category.name]
    : Color.grey50
}))

interface Props {
  categoryCompleted?: boolean
  hasActiveAssessment?: boolean
  category: Category
}

const CategoryProgressBar = ({
  categoryCompleted,
  hasActiveAssessment,
  category
}: Props) => {
  return (
    <Box>
      <ProgressBarBackground
        categoryCompleted={categoryCompleted}
        hasActiveAssessment={hasActiveAssessment}
        category={category}
      >
        <ProgressBarForeground
          hasActiveAssessment={hasActiveAssessment}
          category={category}
        />
      </ProgressBarBackground>
    </Box>
  )
}

export default CategoryProgressBar
