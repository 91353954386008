export enum NavigationLabel {
  Dashboard = 'Dashboard',
  Assessment = 'Assessment',
  GoalManager = 'Goal Manager'
}

export const getNavigations = (
  showGoalManager: boolean,
  showAssessment: boolean
) => {
  const navObjects = [
    { label: NavigationLabel.Dashboard, link: '/equity-index' }
  ]
  if (showAssessment === true) {
    navObjects.push({
      label: NavigationLabel.Assessment,
      link: '/equity-index/assessment'
    })
  }
  if (showGoalManager === true) {
    navObjects.push({
      label: NavigationLabel.GoalManager,
      link: '/equity-index/goal-manager'
    })
  }
  return navObjects
}
