import React, { useState, useEffect, useRef } from 'react'
import { Box, BoxProps, Typography, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { dotLocator } from 'utils/dashboardUtils'

const ProgressCircleContainer = styled(Box)<BoxProps>(() => ({
  position: 'relative',
  width: '175px'
}))

const StyledSvg = styled('svg')(() => ({
  height: '175px',
  width: '175px'
}))

const StyledCircle = styled('circle')(() => ({
  strokeLinecap: 'round',
  fill: 'none',
  strokeWidth: '12px',
  transformOrigin: '50% 50%',
  transform: 'rotate(90deg)',
  strokeDasharray: 499.64,
  strokeDashoffset: 499.64
}))

const colors = ['#B83282', '#38009F', '#068D8E', '#375FC6']
const backgroundColors = ['#F8EBF3', '#ECE7F6', '#E6F4F4', '#E1EAFF']

interface IProps {
  categoryScore?: number
  idx: number
  noAssessmentsCompleted: boolean
  isVisible: boolean
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
  downloadClicked: boolean
  setGraphComplete: React.Dispatch<React.SetStateAction<boolean>>
}

const CategoryProgressCircle = ({
  categoryScore,
  idx,
  noAssessmentsCompleted,
  isVisible,
  setIsVisible,
  downloadClicked,
  setGraphComplete
}: IProps) => {
  const [totalScore, setTotalScore] = useState<number[]>([])
  // const [isVisible, setIsVisible] = useState(false)
  const myRef = useRef<HTMLDivElement>(null!)

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      const entry = entries[0]
      setIsVisible(entry.isIntersecting)
    })
    observer.observe(myRef.current)
  }, [setIsVisible])

  useEffect(() => {
    if (isVisible) {
      if (downloadClicked && categoryScore) {
        setTotalScore(new Array(categoryScore).fill(1))
        if (categoryScore && totalScore.length >= categoryScore) {
          setGraphComplete(true)
          return
        }
      } else {
        if (categoryScore && totalScore.length < categoryScore) {
          const countUp = setInterval(() => {
            setTotalScore([...totalScore, 1])
          }, 50)
          return () => clearInterval(countUp)
        }
      }
    }
  }, [categoryScore, totalScore, isVisible, setGraphComplete, downloadClicked])

  return (
    <ProgressCircleContainer ref={myRef}>
      <StyledSvg>
        {dotLocator.map((coordinate, index) => (
          <StyledCircle
            key={index}
            cx={`${coordinate[0]}`}
            cy={`${coordinate[1]}`}
            r='79.65'
            style={{
              stroke: noAssessmentsCompleted ? '#F7F7F7' : backgroundColors[idx]
            }}
          />
        ))}
        {!noAssessmentsCompleted &&
          totalScore.map((score: number, index: number) => (
            <StyledCircle
              key={index}
              cx={`${dotLocator[index][0]}`}
              cy={`${dotLocator[index][1]}`}
              r='79.65'
              style={{
                stroke: colors[idx]
              }}
            />
          ))}
      </StyledSvg>
      {!noAssessmentsCompleted && (
        <Grid
          container
          justifyContent='center'
          sx={{
            position: 'absolute',
            top: 70
          }}
        >
          <Typography
            variant='h1'
            sx={{ fontSize: '48px', color: colors[idx], fontWeight: 400 }}
          >
            {totalScore.length}
          </Typography>
          <Typography
            // TODO - Add this font style to Typography theme
            variant='h6'
            sx={{
              fontSize: '24px',
              color: colors[idx],
              fontWeight: 400,
              alignSelf: 'flex-end'
            }}
          >
            /25
          </Typography>
        </Grid>
      )}
    </ProgressCircleContainer>
  )
}

export default CategoryProgressCircle
