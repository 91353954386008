import React from 'react'
import { Paper, Grid, GridProps, PaperProps, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import SingleSelectQuestion from './SingleSelectQuestion'
import MultiSelectQuestion from './MultiSelectQuestion'
import { Question, QuestionType, QuestionState } from 'model/assessment'
import { Color } from 'components'

const QuestionContainer = styled(Paper)<PaperProps>(() => ({
  width: '100%',
  padding: '32px 0'
}))

export const DividerLine = styled(Grid)<GridProps>(() => ({
  borderTop: `1px solid ${Color.border}`
}))
interface IProps {
  question: Question
  questionOrder: string
  questionState: QuestionState
  setQuestionState: React.Dispatch<any>
  isFollowup: boolean
  setFollowupId: React.Dispatch<any>
}

const QuestionContent = ({
  question,
  questionOrder,
  questionState,
  setQuestionState,
  isFollowup,
  setFollowupId
}: IProps) => {
  const questionLabel = `0${questionOrder}`.slice(-2)
  return (
    <QuestionContainer variant='outlined'>
      <Typography
        variant='subtitle1'
        fontWeight={700}
        letterSpacing={'0.12em'}
        textTransform='uppercase'
        margin='0 0 16px 32px'
      >
        {isFollowup ? 'Follow-up' : `Question ${questionLabel}`}
      </Typography>
      <DividerLine item xs={12} />
      <Typography variant='h5' margin='16px 32px 32px' width='70%'>
        {question.questionText}
      </Typography>
      {question.questionType === QuestionType.SINGLE_SELECT ? (
        <SingleSelectQuestion
          answers={question.assessmentAnswers}
          questionState={questionState}
          setQuestionState={setQuestionState}
          setFollowupId={setFollowupId}
        />
      ) : (
        <>
          <Typography
            variant='h6'
            margin='-16px 32px 24px'
            sx={{ fontWeight: 400, letterSpacing: '-0.02em' }}
          >
            Please select all that apply.
          </Typography>
          <MultiSelectQuestion
            answers={question.assessmentAnswers}
            questionState={questionState}
            setQuestionState={setQuestionState}
            setFollowupId={setFollowupId}
          />
        </>
      )}
    </QuestionContainer>
  )
}

export default QuestionContent
