import React, { useContext } from 'react'
import {
  Paper,
  Typography,
  Button,
  PaperProps,
  ButtonProps
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { MicroServiceContext } from 'context/MicroService'

const BannerContainer = styled(Paper)<PaperProps>(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '42px 32px',
  backgroundColor: '#FFFFFF',
  borderRadius: '8px',
  boxShadow: 'none'
}))

const SendEmailButton = styled(Button)<ButtonProps>(() => ({
  fontFamily: 'Roboto',
  lineHeight: '24px',
  color: '#050B0E',
  padding: '12px 16px',
  backgroundColor: '#F2F3F3',
  '&:hover': {
    backgroundColor: '#F2F3F3'
  }
}))

interface Props {
  setSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const LegacyDataBanner = ({ setSnackbarOpen }: Props) => {
  const { currentAccount } = useContext(MicroServiceContext)

  const slackMessageInfo = {
    id: currentAccount.id.split('/')[0],
    name: currentAccount.user.name,
    employer: currentAccount.employer.name
  }

  interface SlackMessageProps {
    id: string
    name: string
    employer: string
  }

  const onClick = async (slackMessageInfo: SlackMessageProps) => {
    const response = await fetch(`${process.env.REACT_APP_ZAPIER_URL}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      body: JSON.stringify({ slackMessageInfo })
    })
    const data = await response.json()
    if (data) {
      setSnackbarOpen(true)
    }
  }

  return (
    <BannerContainer variant='outlined'>
      <Typography
        variant='h6'
        sx={{ fontSize: '20px', lineHeight: '28px', letterSpacing: '-0.02em' }}
      >
        Looking for your score from a past assessment?
      </Typography>
      <SendEmailButton
        onClick={() => onClick(slackMessageInfo)}
        disableRipple
        variant='contained'
      >
        Request it here
      </SendEmailButton>
    </BannerContainer>
  )
}

export default LegacyDataBanner
